<template>
    <v-container
      fluid
      v-if="isModal"
    >
      <Modal
        :modal="isModal"
        width="650"
        persistent
      >
          <v-card class="pa-5 pt-0 confirm-popup" flat>
            <v-card-title class="d-flex pl-0 pr-0">
              <v-row class="my-0 mr-0 ml-0">
                <v-col class="d-flex align-center py-0 pl-0">
                  <v-card-title class="pa-0 crop-title">Crop Profile Photo</v-card-title>
                </v-col>
                <v-col class="d-flex justify-end pa-0">
                  <v-icon color="darken-1" @click="closeModal">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider/>
            <div class="cropper-container">
              <cropper
                ref="cropper"
                class="cropper"
                :height="450"
                :src="img"
                :stencil-props="{
                  aspectRatio: 1/1
                }"
                @change="doCrop"
                :min-width="300"
                :min-height="300"
              />
            </div>
            <v-card-actions class="d-flex justify-end">
              <v-btn
              class="ml-2 mt-7"
              color="primaryGray1"
              @click="closeModal"
              text
              >
                Cancel
              </v-btn>
              <v-btn
              class="ml-2 mt-7 btn-purple"
              @click="uploadCroppedImage"> Submit </v-btn>
            </v-card-actions>
          </v-card>
      </Modal>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Modal from '@/components/common/Modal';

const namespace = 'profile';
export default {
  name: 'ProfilePicCropper',
  components: {
    Modal,
    Cropper,
  },
  props: {
    filename: {
      type: String,
      default: '',
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: '',
    },
  },
  data() {
    return ({
      imageLoading: false,
      borderVisiblity: false,
      canvas: null,
    });
  },
  methods: {
    ...mapActions(namespace, ['updateProfilePic']),
    closeModal() {
      this.$emit('modalCropper', false);
    },
    doCrop({ canvas }) {
      this.canvas = canvas.toDataURL();
    },
    getFileFormat(filename) {
      const url = this.canvas;
      const byteString = atob(url.split(',')[1]);
      const mimeString = url.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new File([ab], filename, { type: mimeString });
    },
    uploadCroppedImage() {
      const fileData = this.getFileFormat(this.filename);
      this.uploadProfilePic(fileData);
    },
    async uploadProfilePic(file) {
      this.$emit('modalCropper', false);
      this.$emit('onImageLoading', true);
      await this.updateProfilePic(file);
      this.$emit('onImageLoading', false);
    },
  },
};
</script>

<style lang="scss" scoped>
    .cropper-container{
      height: 450px;
    }
    .cropper {
      height: 100%;
      background: #DDD;
    }
</style>
