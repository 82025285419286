<template>
  <v-container class="pa-0">
    <div>
      <v-row class="ma-0" style="border-bottom: 1px solid #d8d8d8;">
        <v-col class="card-heading text-uppercase pt-0 d-flex align-center title-main pl-0">
          User
        </v-col>
        <v-col class="d-flex justify-end pt-0 pr-0">
          <v-btn
          class="pl-10 pr-10 save-btn btn-purple"
          v-if="canAccess"
          @click="toggleModal()">
            Invite
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="list ma-auto users-list" v-for="item in users" :key="item.id" >
        <v-col
        cols="12"
        xs="12" sm="6" md="6" lg="6" xl="6" xxl="6"
        class="py-0 pl-0 d-flex list-avatar">
          <div style="height: 50px; width: 50px;" class="mt-3">
            <AvatarRound
              :size="50"
              :avatar="profilePic(item)"
              :name="memberInitials(item)"
              textStyle="font-size: 16px;"
            />
          </div>
          <div>
            <div class="list-item pb-5" style="margin-left: 20px;">
              <div class="text-wrap">{{ item.firstName }} {{ item.lastName }}</div>
              <div class="list-email">
                {{ item.email }}
              </div><span>{{isPrimary(item)}}</span>
            </div>
          </div>
          <v-col
        v-if="canUpdate(item)"
        :class="{'menu-button': !$vuetify.breakpoint.smAndDown}"
        class="pl-8">
          <div class="d-flex align-center card-body w-100"
          :class="`${$vuetify.breakpoint.mdAndUp && 'justify-end'}`"
          >
            <v-menu bottom left offset-y offset-x content-class="uploaded-content">
              <template v-slot:activator="{ on }">
                <v-btn dark icon height="auto" width="20" v-on="on"
                class="menu-btn mt-3">
                  <v-icon color="black" dense>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="item.invitationStatus === 1">
                  <v-list-item-title @click="openResendConfirmation(item)">
                    <v-btn text class="w-100 justify-start">
                      Resend Invite
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="item.invitationStatus === 1"/>
                <v-list-item v-if="canUpdate(item)">
                  <v-list-item-title>
                    <v-btn
                    dense
                    solo
                    text
                    class="w-100 justify-start"
                    color="#FF0000"
                    @click="openDeleteConfirm(item)">
                      Remove
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          </v-col>
        </v-col>
        <v-row>
        <v-col
        cols="12"
        xs="12" sm="6" md="6" lg="6" xl="6" xxl="6"
        :class="{'drop-down': !$vuetify.breakpoint.md && !$vuetify.breakpoint.sm}"
        class="pa-0 d-flex align-center">
          <div class="list-item">
            <p class="pending-text" v-if="item.invitationStatus === 1">Pending</p>
            <v-select
            v-else
              class="notranslate member-width ma-0"
              dense
              solo
              flat
              :items="options"
              v-model="userRoles[item.id]"
              append-icon="mdi-chevron-down"
              label="Select Role"
              hide-details
              @change="updateRole($event, item)"
              :disabled="!canUpdate(item)"
            ></v-select>
          </div>
        </v-col>
        <v-col
        v-if="canUpdate(item)"
        :class="{'menu-button': !$vuetify.breakpoint.lgAndUp}"
        class="mr-5">
          <div class="d-flex align-center card-body w-100"
          :class="`${$vuetify.breakpoint.mdAndUp && 'justify-end'}`"
          >
            <v-menu bottom left offset-y offset-x content-class="uploaded-content">
              <template v-slot:activator="{ on }">
                <v-btn dark icon height="auto" width="20" v-on="on"
                class="menu-btn">
                  <v-icon color="black" dense>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="item.invitationStatus === 1">
                  <v-list-item-title @click="openResendConfirmation(item)">
                    <v-btn text class="w-100 justify-start">
                      Resend Invite
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="item.invitationStatus === 1"/>
                <v-list-item v-if="canUpdate(item)">
                  <v-list-item-title>
                    <v-btn
                    dense
                    solo
                    text
                    class="w-100 justify-start"
                    color="#FF0000"
                    @click="openDeleteConfirm(item)">
                      Remove
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          </v-col>
        </v-row>
      </v-row>
    </div>
    <v-container fluid v-if="addMemberModal">
      <Modal persistent content-class="new-user-form" :modal="addMemberModal" scrollable>
        <v-card tile flat outlined class="popup">
          <v-card-title class="modal-header-title">
            Invite Member
            <v-spacer></v-spacer>
            <v-icon class="pr-1 cursor-pointer" @click="toggleModal()">mdi-close</v-icon>
          </v-card-title>
          <v-form ref="form" @submit="submitForm">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="py-0">
                <div class="organization-fields">
                  <v-label class="info-label">First Name*</v-label>
                  <BaseInput
                    name="firstName"
                    outlined
                    dense
                    flat
                    v-model="user.firstName"
                    :status="errors('firstName').length ? 'error' : 'normal'"
                    :error-messages="errors('firstName')"
                    @input="$v.user.firstName.$touch()"
                    @blur="$v.user.firstName.$touch()"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="py-0">
                <div class="organization-fields">
                  <v-label class="info-label">Last Name*</v-label>
                  <BaseInput
                    name="lastName"
                    outlined
                    dense
                    v-model="user.lastName"
                    :status="errors('lastName').length ? 'error' : 'normal'"
                    :error-messages="errors('lastName')"
                    @input="$v.user.lastName.$touch()"
                    @blur="$v.user.lastName.$touch()"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <div class="organization-fields">
                  <v-label class="info-label">Email*</v-label>
                  <BaseInput
                    name="email"
                    outlined
                    dense
                    v-model="user.email"
                    :status="errors('email').length ? 'error' : 'normal'"
                    :error-messages="errors('email')"
                    @input="$v.user.email.$touch()"
                    @blur="$v.user.email.$touch()"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="5" class="py-0">
                <div class="organization-fields role">
                  <v-label class="info-label">Role*</v-label>
                  <v-select dense flat outlined :items="options" v-model="user.role"></v-select>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn text class="py-0" color="primaryGray1" @click="toggleModal()">
                Cancel
              </v-btn>
              <v-btn
                class="py-0 submit btn-purple"
                type="submit"
                :disabled="$v.$dirty && $v.$invalid"
              >
                Invite
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </Modal>
    </v-container>
    <template v-if="showDeleteConfirm">
      <v-dialog v-model="showDeleteConfirm" max-width="500">
        <v-card tile flat outlined class="popup resendInvite">
          <v-card-title class="text-capitalize modal-header-title">
            Remove User
            <v-spacer></v-spacer>
            <v-icon class="pr-1 cursor-pointer" @click="showDeleteConfirm = false">
              mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="black--text">
            <p> Are you sure you want to remove the user from {{selectedBusiness.name}}? </p>
            <div> This user will no longer have access to your business accounts. </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showDeleteConfirm = false" class="py-0" color="primaryGray1">
              Cancel
            </v-btn>
            <v-btn
              color="error"
              type="submit"
              class="pa-0 ml-3 submit-btn"
              :loading="loading"
              @click="deleteUser(selectedUserId)"
            >
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-if="openResend">
      <v-dialog v-model="openResend" max-width="500">
        <v-card tile flat outlined class="popup resendInvite">
          <v-card-title class="text-capitalize modal-header-title">
            Resend Invite
            <v-spacer></v-spacer>
            <v-icon class="pr-1 cursor-pointer" @click="openResend = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="black--text text-center">
            Are you sure you want to resend an invite?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="openResend = false" class="py-0" color="primaryGray1">
              Cancel
            </v-btn>
            <v-btn
              color="info"
              type="submit"
              class="pa-0 ml-3 submit-btn"
              :loading="loading"
              @click="resendUserInvite()"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';
import { getOptimizedS3ImageURL } from '@/helpers/';
import Modal from '@/components/common/Modal';
import AvatarRound from '@/components/common/Avatar';

export default {
  components: {
    BaseInput,
    Modal,
    AvatarRound,
  },
  props: {
    through: {
      type: String,
      default: '',
    },
    selectedOrganizationId: {
      type: Number,
      default: null,
    },
    selectedBusiness: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    agencyUsers(data) {
      this.users = data;
      data.forEach((user) => {
        this.userRoles = {
          ...this.userRoles,
          [user.id]: user.role.name,
        };
      });
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('organizations', ['agencyUsers']),
    ...mapGetters('business', ['business']),
    memberInitials() {
      return (member) => {
        const firstName = _.get(member, 'firstName', '').trim();
        const lastName = _.get(member, 'lastName', '').trim();
        let name = `${firstName} ${lastName}`;
        name = name.trim();
        if (name) {
          if (name.indexOf(' ') > 0) {
            const first = name.split(' ');
            name = first[0].charAt(0) + first[1].charAt(0);
          } else {
            name = name.charAt(0) + name.charAt(1);
          }
        }
        return name;
      };
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    canAccess() {
      if (['admin', 'executive', 'agency_owner'].includes(this.role)) {
        return true;
      }
      return this.business.assigned;
    },
    currentUserId() {
      return this.userDetails.id;
    },
    userList() {
      return this.users;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.user[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.user.firstName.required) {
              errors.push('Please provide first name.');
            }
            if (!this.$v.user.firstName.maxLength) {
              errors.push('First name must be less than 50 characters length.');
            }
            break;
          case 'lastName':
            if (!this.$v.user.lastName.required) {
              errors.push('Please provide last name.');
            }
            if (!this.$v.user.lastName.maxLength) {
              errors.push('Last name must be less than 50 characters length.');
            }
            break;
          case 'email':
            if (!this.$v.user.email.required) {
              errors.push('Please provide email address.');
            }
            if (!this.$v.user.email.email) {
              errors.push('Please provide a valid email address.');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },

  data() {
    return {
      openResend: false,
      addMemberModal: false,
      agencyId: '',
      loading: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        role: 'agency_member',
      },
      options: [
        {
          text: 'Owner',
          value: 'agency_owner',
        },
        {
          text: 'Member',
          value: 'agency_member',
        },
      ],
      selectedUserId: null,
      selectedUser: {},
      users: [],
      userRoles: {},
      showDeleteConfirm: false,
    };
  },

  methods: {
    ...mapActions('organizations', [
      'getAgencyUsers',
      'addAgencyMember',
      'updateAgencyMember',
      'deleteAgencyMember',
    ]),
    ...mapActions('user', [
      'resendInvite',
    ]),
    getOptimizedS3ImageURL,
    openResendConfirmation(user) {
      this.selectedUserId = user.id;
      this.openResend = true;
    },
    async resendUserInvite() {
      const res = await this.resendInvite({ id: this.selectedUserId, reference: 'organization' });
      if (res.success) {
        this.openResend = false;
      }
    },
    toggleModal() {
      this.user = {
        firstName: '',
        lastName: '',
        email: '',
        role: 'agency_member',
      };
      this.$v.$reset();
      this.addMemberModal = !this.addMemberModal;
    },
    canUpdate(user) {
      if (this.role === 'agency_owner' && this.currentUserId !== user.id && !this.isPrimary(user)) {
        return true;
      }
      if (this.role === 'agency_owner' && (this.currentUserId === user.id || this.isPrimary(user))) {
        return false;
      }
      return this.canAccess;
    },
    isPrimary(user) {
      return _.get(user, 'isPrimaryOwner') ? '(Primary)' : null;
    },
    profilePic(member) {
      const { profilePic } = member;
      if (profilePic) {
        return profilePic;
      }
      return null;
    },

    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const payload = {
          businessId: this.agencyId,
          ...this.user,
        };
        const result = await this.addAgencyMember(payload);
        if (result.success) {
          this.users.push(result.user);
          await this.$v.$reset();
          this.toggleModal();
        }
        this.loading = false;
      }
    },
    async updateRole(role, user) {
      const payload = {
        businessId: this.agencyId,
        userId: user.id,
        ...user,
        role,
      };
      // eslint-disable-next-line
      payload.setRole = () => {
        this.userRoles = {
          ...this.userRoles,
          [user.id]: role,
        };
      };
      // eslint-disable-next-line
      payload.unSetRole = () => {
        this.userRoles = {
          ...this.userRoles,
          [user.id]: _.get(user, 'role.name'),
        };
      };
      await this.updateAgencyMember(payload);
    },
    openDeleteConfirm(user) {
      this.selectedUser = user;
      this.selectedUserId = user.id;
      this.showDeleteConfirm = true;
    },
    async deleteUser(userId) {
      const { agencyId } = this;
      const result = await this.deleteAgencyMember({ businessId: agencyId, userId });
      if (result.success) {
        this.showDeleteConfirm = false;
        this.users = this.users.filter((user) => user.id !== userId);
      }
    },
  },

  validations: {
    user: {
      firstName: {
        required,
        maxLength: maxLength(50),
      },
      lastName: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
      },
    },
  },
  async mounted() {
    const agencyId = this.$route.params.organizationId || this.selectedOrganizationId;
    this.agencyId = agencyId;
    await this.getAgencyUsers(agencyId);
    this.users = this.agencyUsers;
    this.agencyUsers.forEach((user) => {
      this.userRoles = {
        ...this.userRoles,
        [user.id]: user.role.name,
      };
    });
  },
};
</script>

<style scoped lang="scss">
.menu-button {
  display: none !important;
}
.text-wrap {
  word-break: break-all;
}
.profile-card {
  box-shadow: none !important;
}
.title-main {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $secondary3;
}
.popup {
  font-family: $fontFamily1;
  border: none !important;
  .v-card__title {
    border-bottom: 1px solid #d1d1d1;
    padding-left: 21px;
    padding-right: 21px;
  }
  .v-form {
    padding: 30px 21px;
    .v-card__actions {
      padding: 0;
      justify-content: flex-end;
      .v-btn {
        box-shadow: none;
        border-radius: 30px;
        font-weight: 400;
        font-size: 16px;
        color: $secondary3;
        line-height: 24px;
      }
      .submit {
        font-weight: 700;
        line-height: 28px;
        width: 106px;
        margin-left: 12px;
      }
    }
    .organization-fields {
      .v-label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 31px;
        color: $titleBlack;
      }
      ::v-deep .v-input {
        .v-input__control {
          .v-select__selection,
          input {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #4f4f4f;
          }
        }
      }
    }
  }
}
.drop-down {
  padding-left: 80px !important;
}
.resendInvite {
  .black--text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $titleBlack !important;
    padding: 50px 20px;
  }
  .v-card__actions {
    padding: 14px 27px 18px 27px;
    border-top: 1px solid #d1d1d1;
    .v-btn {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
    }
    .submit-btn {
      width: 112px;
      color: #FFFFFF;
    }
  }
}
.save-btn {
  width: 113px;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
}
.users-list {
  border-bottom: 1px solid #d8d8d8;
  cursor: default;
}
::v-deep .input-field-label {
  font-size: 14px !important;
}
.v-image {
  border-radius: 18px;
  &.no-image {
    border: 2px solid $card-title;
  }
}
.empty-profile-image {
  margin-top: 75px;
}
.context-text {
  font-size: 24px;
  letter-spacing: -0.48px;
}
.complete-profile-button {
  margin: auto;
  height: 50px !important;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(129, 129, 129, 0.5);
  background-color: $primary5 !important;
  color: $neutral6;
  margin-top: 20px;
  ::v-deep .v-btn__content {
    font-size: 16px;
  }
}
.member-width {
  width: 100px;
  ::v-deep .v-input__slot {
    padding: 0px !important;
  }
  ::v-deep .v-select__selection {
    font-size: 16px !important;
    line-height: 24px !important;
    color: $charcoalBlack !important;
    max-width: none !important;
  }
}
.list {
  padding: 15px 0px 0px 0px;
  border-bottom: 1px solid #d8d8d8;
  .col {
      display: flex;
      align-items: center;
  }
  .list-item {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $charcoalBlack;
      .pending-text {
        font-style: italic;
         margin-bottom: 0px;
      }
  }
}
@media (max-width: 600px) {
  .users-list {
    padding-bottom: 10px !important;
  }
  .list-avatar {
    padding-top: 5px !important;
  }
  .list-item {
    padding-bottom: 0px !important;
  }
  .list-email {
    overflow-x: hidden;
    -ms-text-overflow:ellipsis;
    text-overflow: ellipsis;
    width: 150px;
  }
  .list-email:hover {
    overflow: visible;
  }
  .container {
    padding-top: 60px;
  }
}
::v-deep .normalState .v-input__slot {
  border-color: #e0e0e0 !important;
}
::v-deep .nullState .v-input__slot {
  border-color: #e0e0e0 !important;
}
</style>
