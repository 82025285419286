<template>
  <v-row class="ma-0 d-flex align-center">
    <v-col class="activity-settings pa-0">
      <v-row class="ma-0 white heading pl-5 d-flex align-center">
        <v-col cols=6 md=8 class="pl-2 mobile-activity-heading">
          <v-list-item class='pa-0'>
            <v-list-item-content class="pa-0">
              <v-list-item-title class="font-h5 pb-1"> NOTIFICATIONS </v-list-item-title>
              <v-list-item-subtitle class="title-caption">
                Notify me when ... </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols=3 md=2 class="px-2 alert-title align-end font-h5">
          Email
        </v-col>
        <v-col cols=3 md=2 class="px-2 alert-title align-bottom font-h5">
          SMS
        </v-col>
      </v-row>
      <v-divider  color="#c4c4c4"> </v-divider>
      <template v-for="(alert, index) in items">
        <div :key="alert.activityAlertId">
          <v-row class="ma-0 pl-5 alert">
            <v-col cols=6 md=8 class="pl-2 py-1 heading">{{alert.id}}
              <v-list-item class='px-0'>
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="font-body"
                    v-text="alert.name"
                  >
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="font-body-small font-italic pt-1"
                    v-text="alert.subText"
                  >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols=3 md=2 class="px-2 d-flex align-center mobile-switch">
                <v-switch
                  inset
                  dense
                  hide-details
                  v-model="alert.email"
                  :light=true
                  @change="checkEditStatus"
                >
                </v-switch>
            </v-col>
            <v-col cols=3 md=2 class="pl-2 d-flex align-center mobile-switch">
              <v-switch
                inset
                hide-details
                v-model="alert.smsText"
                :light=true
                dense
                @change="checkEditStatus"
              >
              </v-switch>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-divider v-if="index < userDetails.activityAlerts.length - 1"> </v-divider>
          </v-row>
        </div>
      </template>
      <template v-if="modified">
        <v-row class="ma-0 px-4 py-2">
          <v-col class="d-flex justify-end pb-0">
            <v-btn text @click="cancel" small
              class="pa-0 font-family-2"
              color="primaryGray1"
            >
              <span class="font-family-2">Cancel</span>
            </v-btn>
            <v-btn text @click="updateAlerts" small
              class="pa-0 font-family-2  btn-purple"
            >
              <span class="font-family-2">Save</span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    editConfirm: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    activityAlerts(newVal) {
      if (newVal.length !== this.items.length) {
        this.updateCurrentState();
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    activityAlerts() {
      return this.userDetails.activityAlerts;
    },
    modified() {
      return !_.isEqual(this.itemsBeforeEdit, this.items);
    },
  },
  data() {
    return {
      items: [],
      payload: [],
      itemsBeforeEdit: [],
    };
  },
  methods: {
    ...mapActions('user', ['updateActivityAlert']),
    async updateAlerts() {
      await this.updateActivityAlert({ alerts: this.items });
      this.itemsBeforeEdit = _.cloneDeep(this.items);
    },
    cancel() {
      this.items = _.cloneDeep(this.itemsBeforeEdit);
    },
    updateCurrentState() {
      _.map(this.activityAlerts, (alert) => {
        const item = { activityAlertId: alert.id, name: alert.name, subText: alert.subText };
        if (alert.userActivityAlert) {
          this.items.push({
            ...item,
            ...{ email: alert.userActivityAlert.email, smsText: alert.userActivityAlert.smsText },
          });
        } else {
          this.items.push({
            ...item,
            ...{
              email: alert.defaultSetting === '1' || alert.defaultSetting === '3',
              smsText: alert.defaultSetting === '2' || alert.defaultSetting === '3',
            },
          });
        }
      });
      this.itemsBeforeEdit = _.cloneDeep(this.items);
    },
    checkEditStatus() {
      if (this.editConfirm) {
        this.$emit('show-confirm');
        this.$nextTick(() => {
          this.items = _.cloneDeep(this.itemsBeforeEdit);
        });
      }
    },
  },
  mounted() {
    this.updateCurrentState();
  },
};
</script>


<style scoped lang="scss">
  .title-caption {
    color: $darkGray !important;
    font-family: $fontFamily1;
    font-size: 12px;
    line-height: 1.4;
  }
</style>
