<template v-if="showConfirm">
  <v-dialog v-model="showConfirm" max-width="489">
    <v-card class="affiliation pa-3" flat>
      <v-row class="title-header">
        <v-col class="d-flex align-center py-0 pl-0" cols="10">
          <span class="text modal-header-title"> Remove Affiliation </span>
        </v-col>
        <v-col class="d-flex justify-end py-0 pr-0">
          <v-icon color="darken-1" @click="$emit('close')">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-row class="info-text">
        <v-card-text class="modal-heading pa-0">
          <p>
            {{
              `Are you sure you want to remove the affiliation between
                ${agencyName} and ${selectedBusinessName}?`
            }}
          </p>
          <p>
            Your shared workspace will become dormant. You’ll still be able to access and download
            any digital assets that were uploaded to that shared workspace.
          </p>
        </v-card-text>
      </v-row>
      <v-row>
        <v-card-actions>
          <v-btn text class="py-0" color="primaryGray1" @click="$emit('close')">
            Cancel
          </v-btn>
          <v-btn class="py-0 submit" type="submit" @click="$emit('remove')">
            Remove
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'RemoveAffiliationPopup',
  props: {
    showConfirm: {
      type: Boolean,
      default: false,
    },
    agencyName: {
      type: String,
      default: '',
    },
    selectedBusinessName: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.affiliation {
  .v-card__actions {
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #e0e0e0;
    padding: 14px 15px 6px;
    .v-btn {
      border-radius: 6px;
      font-weight: 500;
      line-height: 19px;
      font-size: 16px;
      color: $secondary3;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      ::v-deep .v-btn__content {
        text-transform: uppercase;
      }
    }
    .submit {
      ::v-deep .v-btn__content {
        color: #fff !important;
      }
      background: $colorRed !important;
      width: 112px;
      height: 40px;
      margin-left: 12px !important;
    }
  }
  .info-text {
    padding: 28px 21px 34px;
    p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $titleBlack;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
  .title-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 4px 21px 21px;
  }
  .modal-heading {
    font-family: $fontFamily1;
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    color: #1b1c1e !important;
  }
  .cancel-btn {
    font-family: $fontFamily1;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b;
  }
}
</style>
