<template>
  <div class="info-container">
    <v-row class="ma-0 px-4 py-2">
      <v-col class="d-flex justify-start font-h5">
        YOUR INFO
      </v-col>
      <v-col class="d-flex justify-end pa-0 pt-1">
        <v-btn v-if="mode === 'edit'" text class="px-1" color="primaryGray1"
          @click="toggleCancel">
          <span class="pr-2">Cancel</span>
        </v-btn>
        <v-btn text icon @click="toggleMode" class="btn-purple">
          <img v-if="mode === 'normal'" src="@/assets/svg/theme/edit.svg" class="edit-profile"/>
          <span v-else class="save-btn">Save</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider color="#D8D8D8"> </v-divider>
    <div class="py-2">
      <v-row class="ma-0 px-4 d-flex align-center pb-2">
        <v-col cols="3" class="pa-1 pl-3 d-flex justify-start font-label">
          Photo
        </v-col>
        <v-col cols="1" class="pa-1 justify-start">
          <Upload
            acceptedFiles="image/x-png,image/jpeg"
            v-model="file"
            mode="justImage"
            @change="loadImage">
            <template v-slot:default="{filePreview}">
              <div class="d-flex profile-image-container"
                :class="borderVisiblity ? 'border-none' : ''"
              >
                <v-img
                  class="profile-image"
                   :src="getOptimizedS3ImageURL(image(filePreview), {
                      width: 100,
                      height: 100,
                    })"
                  alt="Profile"
                />
                <div class="profile-image-loading" v-if="imageLoading">
                  <v-progress-circular indeterminate color="grey"/>
                </div>
                <div class="profile-image-overlay">
                  <img src="@/assets/svg/camera_event.svg" />
                  <span>Update</span>
                </div>
              </div>
            </template>
          </Upload>
        </v-col>
      </v-row>
      <v-row
        v-for="(detail, i) in basicDetails"
        :key="`basic${i}`"
        :class="{'hidden-screen-only': (!detail.value && mode === 'normal')}"
        class="ma-0 px-4 d-flex align-center"
      >
        <v-col :class="mode" cols="3"
          class="pa-1 pl-3 d-flex justify-start font-label overflow-wrap-anywhere">
          {{detail.placeholder}}
        </v-col>
        <v-col cols="9" class="pa-1">
          <BaseInput
            v-if="detail.name === 'number' && mode === 'edit'"
            :key="`input${i}`"
            v-mask="'###-###-####'"
            type="tel"
            outlined
            dense
            :mode="mode"
            :disabled="detail.disable"
            :name="detail.name"
            v-model.trim="profile[detail.name]"
            :status="errors(detail.name).length ? 'error': 'normal'"
            :error-messages="errors(detail.name)"
            @input="$v.profile[detail.name].$touch()"
            @blur="$v.profile[detail.name].$touch()"
          >
            <div class='mb-2 font-body'> {{ detail.placeholder }} </div>
          </BaseInput>
          <BaseInput
            v-else
            outlined
            dense
            :mode="mode"
            :disabled="detail.disable"
            :name="detail.name"
            v-model.trim="profile[detail.name]"
            :status="errors(detail.name).length ? 'error': 'normal'"
            :error-messages="errors(detail.name)"
            @input="$v.profile[detail.name].$touch()"
            @blur="$v.profile[detail.name].$touch()"
          >
            <div class='mb-2 font-body'>
              <span class="notranslate"> {{ detail.value }} </span>
            </div>
          </BaseInput>
        </v-col>
      </v-row>
      <v-row class="ma-0 px-4 d-flex align-center">
        <v-col cols="3" class="font-label pa-1 pl-3 overflow-wrap-anywhere">
          Password
        </v-col>
        <v-col cols="6" md=7 lg=8 class="pa-1 font-body">
          *********
        </v-col>
        <v-col cols="3" md=1 class="pa-1">
          <v-btn text class="px-1 btn-purple"
          @click="showPasswordForm = true">
          <span class="pr-2">Edit</span>
        </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-container
      fluid
      v-if="emailEdit"
    >
      <Modal
        :modal="emailEdit"
        width="650"
        persistent
      >
          <v-card class="pa-5 confirm-popup" flat>
            <v-row>
              <v-col class="d-flex align-center pb-0" cols="11">
                <div class="modal-header-title"> Confirm email update </div>
              </v-col>
            </v-row>
            <v-row class="pa-0">
              <v-col class="font-family-2">
                Please note that you'll be logged out now.
                We’ll send you a confirmation email with a link to confirm your new email address.
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 font-family-2" xs="12" sm="8">
                <BaseInput
                  outlined
                  dense
                  label="New Email*"
                  name="email"
                  v-model.trim="profile.email"
                  disabled
                >
                </BaseInput>
              </v-col>
              <v-col cols="12" class="d-flex justify-end py-0">
                  <v-btn text
                    @click="cancelEmailUpdate"
                    class="py-0"
                    color="primaryGray1"
                  >
                    Cancel
                  </v-btn>
                  <v-btn text
                    type="submit"
                    class="ml-2 btn-purple"
                    @click="sendConfirmationEmail"
                  >
                    Confirm
                  </v-btn>
              </v-col>
            </v-row>
          </v-card>
      </Modal>
     </v-container>
     <v-container
      fluid
      v-if="showPasswordForm"
    >
      <Modal
        :modal="showPasswordForm"
        width="500"
        persistent
      >
        <v-card class="confirm-popup" flat>
          <ChangePassword
            @close-form="showPasswordForm = false"
          />
        </v-card>
      </Modal>
     </v-container>
    <ProfileCropper
      :filename="filename"
      :img="img"
      :isModal="isModal"
      @onImageLoading="imgLoader"
      @modalCropper="isCropModal"
    />
    <template>
      <v-snackbar
        v-model="profileSize"
        color='#ff0808'
        multi-line
        top
        right
        :timeout="timeout"
      >
      {{message}}
        <v-icon
          dark
          right
          @click="profileSize = false"
        >
          mdi-close-circle
        </v-icon>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import _ from 'lodash';
import BaseInput from '@/components/common/BaseInput';
import ChangePassword from '@/components/common/ChangePassword';
import Modal from '@/components/common/Modal';
import Upload from '@/components/common/Upload';
import { getOptimizedS3ImageURL } from '@/helpers/';
import ProfileCropper from '@/components/profile/common/ProfileCropper';

export default {
  components: {
    BaseInput,
    ChangePassword,
    Modal,
    Upload,
    ProfileCropper,
  },
  directives: {
    mask,
  },
  props: {
    email: {
      type: String,
      required,
    },
  },
  computed: {
    ...mapGetters('profile', ['clientProfile', 'agencyCustomer']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    agency_customer() {
      return ['agency_owner', 'agency_member'].includes(this.role);
    },
    profile() {
      if (this.agency_customer) {
        return this.agencyCustomer;
      }
      return this.clientProfile;
    },
    basicDetails() {
      return ([
        {
          name: 'firstName',
          placeholder: 'First Name',
          value: this.profile.firstName,
          avatar: require('@/assets/svg/theme/phone-outline.svg'),
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          value: this.profile.lastName,
          avatar: require('@/assets/svg/theme/phone-outline.svg'),
        }, {
          name: 'email',
          placeholder: 'Email',
          value: this.profile.email,
          avatar: require('@/assets/svg/theme/email-outline.svg'),
        }, {
          name: 'number',
          placeholder: 'Phone Number',
          value: this.profile.number,
          avatar: require('@/assets/svg/theme/phone-outline.svg'),
        },
      ]);
    },
    image() {
      return (filePreview) => {
        const profilePic = _.get(this.userDetails, 'profilePic');
        if (filePreview) {
          return filePreview;
        }
        if (profilePic && profilePic !== 'null') {
          this.setBorderVisibility();
          return profilePic;
        }
        return require('@/assets/svg/users.svg');
      };
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.profile[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.profile.firstName.required) { errors.push('Please provide your first name.'); }
            break;
          case 'lastName':
            if (!this.$v.profile.lastName.required) { errors.push('Please provide your last name.'); }
            break;
          case 'email':
            if (!this.$v.profile.email.required) { errors.push('Please provide your email.'); }
            if (!this.$v.profile.email.email) { errors.push('Please provide a valid email.'); }
            break;
          case 'number':
            if (!this.$v.profile.number.required) { errors.push('Please provide your phone number.'); }
            if (!this.$v.profile.number.minLength) { errors.push('Please provide valid phone number'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return {
      file: '',
      imageLoading: false,
      borderVisiblity: false,
      emailEdit: false,
      mode: 'normal',
      showPasswordForm: false,
      isModal: false,
      filename: '',
      img: '',
      timeout: 6000,
      message: '',
      profileSize: false,
    };
  },
  methods: {
    ...mapActions('profile', ['clientProfileUpdate', 'getProfile', 'producerProfileUpdate']),
    ...mapActions('user', ['logoutUser']),
    getOptimizedS3ImageURL,
    toggleCancel() {
      this.mode = 'normal';
      this.getProfile();
    },
    cancelEmailUpdate() {
      this.$v.$reset();
      this.emailEdit = false;
    },
    setBorderVisibility() {
      this.borderVisiblity = true;
    },
    loadImage(file) {
      this.file = file;
      const { name } = file;
      const parts = name.split('.');
      const ext = parts[parts.length - 1].toLowerCase();
      if (ext === 'png' || ext === 'jpeg' || ext === 'jpg') {
        if (file.size > process.env.VUE_APP_PROFILE_SIZE) {
          this.profileSize = true;
          this.message = `That’s a very nice image, but it’s a bit too big.
      Try images less than 10mb file size.`;
        } else {
          this.message = '';
          this.profileSize = false;
          this.filename = name;
          this.isModal = true;
          if (file) {
            if (this.img) {
              URL.revokeObjectURL(this.img);
            }
            const blob = URL.createObjectURL(file);
            const reader = new FileReader();
            reader.onload = () => {
              this.img = blob;
            };
            reader.readAsArrayBuffer(file);
          }
        }
      } else {
        this.profileSize = true;
        this.message = 'File format not supported. Please upload image/png, image/jpeg';
      }
    },
    imgLoader(bool) {
      this.imageLoading = bool;
    },
    isCropModal(val) {
      this.isModal = val;
    },
    async sendConfirmationEmail() {
      const actionToCall = this.agency_customer
        ? this.producerProfileUpdate : this.clientProfileUpdate;
      const payload = Object.assign(this.profile, { emailUpdated: false });
      const response = await actionToCall(payload);
      if (response.success) {
        await this.logoutUser();
        this.$router.push('/');
      }
    },
    async toggleMode() {
      if (this.mode === 'normal') {
        this.mode = 'edit';
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          if (this.email !== this.profile.email) {
            this.emailEdit = true;
          } else {
            const actionToCall = this.agency_customer
              ? this.producerProfileUpdate : this.clientProfileUpdate;
            const response = await actionToCall(this.profile);
            if (response.success) {
              this.mode = 'normal';
            }
          }
        }
      }
    },
  },
  validations: {
    profile: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      number: {
        required,
        minLength: minLength(12),
      },
      email: {
        required,
        email,
      },
    },
  },
};
</script>

<style scoped lang="scss">
  .info-container {
    .profile-image-container {
        width: 75px;
        height: 75px;
        border: 2px solid #565682;
        border-radius: 22px;
        cursor: pointer;
        position: relative;
    }
    .profile-image {
        width: 100%;
        object-fit: contain;
        border-radius: 20px;
    }
    .profile-image-loading {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .profile-image-overlay {
        position: absolute;
        height: 35px;
        bottom: 0;
        background: $neutral5;
        display: none;
        width: 100%;
        border-radius: 0 0 16px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .profile-image-container:hover .profile-image-overlay {
        display: flex;
    }
  }
</style>
