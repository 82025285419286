<template>
  <div>
    <v-row class="ma-0">
      <v-col cols=12 md=6 class="px-0 py-0 mb-3">
        <div class="info-container">
          <v-row class="ma-0 px-4 py-2">
            <v-col class="d-flex justify-start info-title">
              YOUR INFO
            </v-col>
          </v-row>
          <v-divider color="#c4c4c4"> </v-divider>
          <v-row class="ma-0 px-4 d-flex pt-2 align-start">
            <v-col cols="3" class="label skillset-label py-0 d-flex align-center" >
              Skill Set
            </v-col>
            <v-col cols="9" class="pa-0">
              <v-row v-for="item in profile.skillsets" :key="item.id"
                class="ma-0 d-flex align-start"
              >
                <v-col cols="4" class="pa-0 skill-name d-flex align-center"> {{item.name}} </v-col>
                <v-col cols="8" class="pa-0 pr-3">
                  <v-chip v-for="item in paytiers(item)"
                    text-color="primary" color="#e5e7fa" :key="item"
                    class="ml-1 mt-1 font-family-2 full-width">
                    {{item}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-0 px-4 d-flex align-center py-2">
            <v-col cols="3" class="label pa-1 pl-3 overflow-wrap-anywhere">
              Password
            </v-col>
            <v-col cols="6" md=6 lg=7 class="pa-1 pt-2">
              *********
            </v-col>
            <v-col cols="3" md=2 class="pa-1 d-flex justify-end">
              <v-btn text color="info" class="px-0" @click="showPasswordForm = true" small>
                <span class="font-family-2">Edit</span>
              </v-btn>
            </v-col>
            <v-col cols=9>
              <div class="info-caption">
                Contact your Community Manager if you’re interested in making changes
                to your skill set and pay tier.
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="false" class="info-container mt-3">
          <v-row class="ma-0 px-4 py-2">
            <v-col cols=8>
              <v-list-item-content class="pa-0">
                <v-list-item-title class="info-title pb-1">
                  PROJECT PREFERENCES
                </v-list-item-title>
                <v-list-item-subtitle class="title-caption py-1">
                  Help Blended Sense match you to the right projects. </v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
            <v-col class="d-flex justify-end pb-0">
              <v-btn v-if="tagMode!=='normal'" small class="pa-0"
                text color="primaryGray1" @click="toggleCancel()">
                <span class="pr-2 font-family-2">Cancel</span>
              </v-btn>
              <v-btn text color="info" @click="toggleMode('projectPreferences')" small
                class="pa-0 font-family-2"
              >
                <span v-if="tagMode ==='normal'" class="font-family-2">Edit</span>
                <span v-else class="save-btn font-family-2">SAVE</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider color="#c4c4c4"> </v-divider>
          <v-row class="ma-0 px-4 d-flex align-center pt-2">
            <v-col cols=12 class="pa-0">
              <v-list
                subheader
              >
                <v-list-item v-for="(tag, i) in tags()" :key="`tag${i}`" class="px-2">
                  <v-list-item-content
                    :class="{'hidden-screen-only':
                    (!profile[tag.name] && tagMode === 'normal')}"
                    class="pb-0"
                  >
                    <v-list-item-title
                      v-text="tag.title"
                      class="pb-1 font-family-2 black--text"
                    >
                    </v-list-item-title>
                      <CustomComboBox
                        v-if="tag.type === 'combo'"
                        :mode="tagMode"
                        multiple
                        return-object
                        :items="tag.items"
                        item-text="name"
                        item-value="id"
                        :placeholder="tag.title"
                        :name="tag.name"
                        v-model="profile[tag.name]"
                        @input="$v.profile[tag.name].$touch()"
                        @blur="$v.profile[tag.name].$touch()"
                        class="pa-0"
                      >
                        <v-chip
                          color="#e5e7fa"
                          label
                          v-for="(set, j) in tag.values"
                          :key="`${tag.title}-${j}`"
                          class="mr-3 mb-2"
                        >
                            {{ set.name }}
                        </v-chip>
                      </CustomComboBox>
                      <AutoCompleteField
                        v-else
                        :mode="tagMode"
                        multiple
                        :items="tag.items"
                        item-text="name"
                        item-value="id"
                        :placeholder="tag.title"
                        :name="tag.name"
                        v-model="profile[tag.name]"
                        @input="$v.profile[tag.name].$touch()"
                        @blur="$v.profile[tag.name].$touch()"
                      >
                        <v-chip
                          color="#e5e7fa"
                          label
                          v-for="(id, j) in tag.values"
                          :key="`${tag.title}-${j}`"
                          class="mr-3 mb-2"
                        >
                          {{ getText(id, tag.items, 'name') }}
                        </v-chip>
                      </AutoCompleteField>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </div>
        <div>
          <CreativeReimbursement/>
        </div>
      </v-col>
      <v-col cols=12 md=6 class="pa-0 pl-md-2">
        <div class="info-container profile-aval">
            <ProfileAvailability
            />
        </div>
        <div class="info-container">
          <NotificationSettings
            :editConfirm="editConfirm"
            @show-confirm="() => { showConfirm = true }"
          />
        </div>
      </v-col>
    </v-row>
     <v-container
      fluid
      v-if="showPasswordForm"
      class="pa-0"
    >
      <Modal
        :modal="showPasswordForm"
        width="500"
        persistent
      >
        <v-card class="confirm-popup" flat>
          <ChangePassword
            @close-form="showPasswordForm = false"
          />
        </v-card>
      </Modal>
    </v-container>
    <v-container
      fluid
      v-if="showConfirm"
      class="pa-0"
    >
      <Modal
        :modal="showConfirm"
        width="500"
        persistent
      >
        <v-card class="py-4" flat>
          <v-card-title class="black--text">
            Please save current changes before editing another section.
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="info"
              @click="showConfirm = false"
              class="py-0"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </Modal>
    </v-container>
    <v-container v-if="showStatusConfirm">
      <Modal
        :modal="showStatusConfirm"
        width="500"
        persistent
      >
        <v-card class="modal-card pa-4" flat>
          <v-card-text class="pa-2 black--text fontSize font-family-2 card-text">
          Are you sure you want to update your status to inactive?
          You will not receive Sweep invites in inactive status.
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
            <v-btn text color="primaryGray1"
            @click="cancelStatusUpdate"
            class="py-0 status-btn"
          >
            No
          </v-btn>
          <v-btn text color="info"
            @click="updateUserStatusApi()"
            class="py-0 pr-2 status-btn"
            :loading="btnLoading"
          >
            Yes
          </v-btn>
        </v-card-actions>
        </v-card>
      </Modal>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import _ from 'lodash';
import store from '@/store';
import ChangePassword from '@/components/common/ChangePassword';
import Modal from '@/components/common/Modal';
import AutoCompleteField from '@/components/common/AutoCompleteField';
import CustomComboBox from '@/components/common/CustomComboBox';
import NotificationSettings from '@/components/common/NotificationSettings';
import ProfileAvailability from '@/components/profile/creative/ProfileAvailability';
import CreativeReimbursement from '@/components/profile/creative/CreativeReimbursement';

export default {
  components: {
    AutoCompleteField,
    ChangePassword,
    CustomComboBox,
    Modal,
    NotificationSettings,
    ProfileAvailability,
    CreativeReimbursement,
  },
  directives: {
    mask,
  },
  watch: {
    profile: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('profile', ['skillsets', 'interests', 'cameraEquipments', 'lensEquipments', 'accessories', 'styles', 'subjects', 'writerInterests', 'paytiers']),
    ...mapGetters('profile', { profile: 'creativeProfile' }),
    ...mapGetters('user', ['userDetails']),
    paytiers() {
      return (skillset) => {
        const payTiers = _.filter(this.profile.payTiers, ['skillSetId', skillset.id]);
        if (skillset.name === 'Talent') {
          return _.map(payTiers, (tier) => `${_.capitalize(tier.payTier)} - $${tier.rate}`);
        }
        return _.map(payTiers, (tier) => `${_.capitalize(tier.payTier)} - $${tier.rate}/hr`);
      };
    },
  },
  data() {
    return {
      mode: 'normal',
      showPasswordForm: false,
      tagMode: 'normal',
      editConfirm: false,
      showConfirm: false,
      showStatusConfirm: false,
      btnLoading: false,
      sectionToupdate: null,
    };
  },
  methods: {
    ...mapActions('profile', ['creativeProfileUpdate', 'getProfile']),
    ...mapActions('user', ['logoutUser', 'creativeStatusUpdate']),
    preference() {
      const skills = _.map(this.profile.skillsets, 'name');
      return _.intersection(['Photographer', 'Videographer', 'Copywriter'], skills).length > 0;
    },
    getText(id, aObj, type) {
      const obj = _.find(aObj, (x) => x.id === id);
      return _.get(obj, type);
    },
    tags() {
      const skills = this.profile.skillsets;
      if (skills && skills.length) {
        const skillsets = _.map(skills, 'name');
        const photoVideoEditor = skillsets.some((x) => ['Photographer', 'Videographer', 'Video Editor'].includes(x));
        const copyWriter = skillsets.some((x) => ['Copywriter'].includes(x));

        return _.compact([
          photoVideoEditor && {
            name: 'cameraEquipments',
            title: 'Camera',
            items: this.cameraEquipments,
            values: this.profile.cameraEquipments,
            type: 'combo',
          },
          photoVideoEditor && {
            name: 'lensEquipments',
            title: 'Lens',
            items: this.lensEquipments,
            values: this.profile.lensEquipments,
            type: 'combo',
          },
          photoVideoEditor && {
            name: 'accessories',
            title: 'Accessories',
            items: this.accessories,
            values: this.profile.accessories,
            type: 'combo',
          },
          photoVideoEditor && {
            name: 'interestIds',
            title: 'Interests',
            items: this.interests,
            values: this.profile.interestIds,
            type: 'select',
          },
          copyWriter && {
            name: 'styleIds',
            title: 'Styles',
            items: this.styles,
            values: this.profile.styleIds,
            type: 'select',
          },
          copyWriter && {
            name: 'writerInterestIds',
            title: 'Writer Interests',
            items: this.writerInterests,
            values: this.profile.writerInterestIds,
            type: 'select',
          },
          copyWriter && {
            name: 'subjectIds',
            title: 'Writer Subjects',
            items: this.subjects,
            values: this.profile.subjectIds,
            type: 'select',
          },
        ]);
      }
      return [];
    },
    toggleCancel() {
      this.tagMode = 'normal';
      this.editConfirm = false;
      this.getProfile();
    },
    async toggleMode(section) {
      this.sectionToupdate = section;
      if (this.tagMode === 'normal') {
        this.tagMode = 'edit';
        this.editConfirm = true;
      } else {
        // eslint-disable-next-line max-len
        const response = await this.creativeProfileUpdate({ ...this.profile, sectionToUpdate: this.sectionToupdate });

        if (response.success) {
          this.tagMode = 'normal';
          this.sectionToupdate = null;
        }
        this.editConfirm = false;
      }
    },
    async updateUserStatus(val) {
      if (val === '2') {
        this.showStatusConfirm = true;
      } else {
        this.showStatusConfirm = false;
        this.updateUserStatusApi();
      }
    },
    async updateUserStatusApi() {
      this.btnLoading = true;
      const response = await this.creativeStatusUpdate({
        availability: this.userDetails.availability,
      });
      if (response.success) {
        this.showStatusConfirm = false;
      }
      this.btnLoading = false;
    },
    cancelStatusUpdate() {
      this.showStatusConfirm = false;
      const user = { ...this.userDetails, availability: '1' };
      store.commit('user/SET_USER', user);
    },
  },
};
</script>

<style scoped lang="scss">
.profile-aval {
  margin-bottom: 10px;
}
  .info-container {
    background-color: #fff;
    border: 1px solid $lightSilver;
    .info-title {
      color: $charcoalBlack;
      font-size: 13px !important;
      font-weight: bold;
    }
    .label {
      color: $darkGray;
      font-family: $fontFamily1;
      font-weight: 900;
      &.skillset-label {
        height: 40px;
      }
    }
    .skill-name {
      height: 40px;
    }
    .info-caption {
      color: black;
      font-family: $fontFamily1;
      font-size: 12px;
      font-style: italic;
    }
    .title-caption {
      color: $darkGray;
      font-family: $fontFamily1;
      font-size: 12px;
      line-height: 1.4;
    }
    .status-caption {
      color: $darkGray;
      font-size: 13px;
      font-style: italic;
      line-height: 1.4;
    }
    .profile-image-container {
        width: 75px;
        height: 75px;
        border: 2px solid #565682;
        border-radius: 22px;
        cursor: pointer;
        position: relative;
    }
    .profile-image {
        width: 100%;
        object-fit: cover;
        border-radius: 22px;
    }
    .profile-image-loading {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .profile-image-overlay {
        position: absolute;
        height: 35px;
        bottom: 0;
        background: $neutral5;
        display: none;
        width: 100%;
        border-radius: 0 0 16px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .profile-image-container:hover .profile-image-overlay {
        display: flex;
    }
  }
  .combo-box {
      margin-bottom: 0;
    }
  ::v-deep .v-chip__content {
    color: $primary2;
  }
</style>
