<template>
  <v-container
    class="ma-0 settings-wrapper pb-8 px-2 h-100"
    :class="{ 'bg-white': enableSettings }"
  >
    <v-row class="d-flex align-center justify-center settings" v-if="enableSettings">
      <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? 'pb-10' : 'pb-4'">
        <PageTitle />
      </v-col>
      <OrganizationSettings
        :reference="agency_customer ? 'organization' : 'business'"
        :selectedBusinessId="userDetails.currentBusinessId"
      />
    </v-row>
    <v-row
      class="d-flex align-center justify-center ma-auto"
      v-else-if="enableCustomerProfile || enableOrganizationProfile"
    >
      <v-col
      cols="12" xs="12" sm="12" md="8" lg="8" xl="8" xxl="8"
      class="pa-0"
      :class="$vuetify.breakpoint.mdAndUp ? 'pb-10' : 'pb-4'">
        <PageTitle />
      </v-col>
      <v-col
      cols="12" xs="12" sm="12" md="8" lg="8" xl="8" xxl="8"
      class="pa-0">
        <v-col cols="2" style="align-self:start;" class="pa-0 mb-2">
          <v-select
            class="notranslate lang-translator"
            v-model="currentLanguage"
            :items="$hyper.languages"
            item-text="name"
            item-value="code"
            label="Language"
            return-object
            solo
            @select="changeLanguage"
            flat
            style="min-width:150px; height:50px;"
            :menu-props="{ contentClass: 'notranslate' }"
            ref="languageSelector"
          >
            <template v-slot:item="{ item }">
              <v-list-item class="notranslate" @click="changeLanguage(item)">
                {{ item.name }}</v-list-item
              >
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" class="settings-info pa-0">
          <ClientProfileSettings :email="email" />
        </v-col>
      </v-col>
      <v-col
      cols="12" xs="12" sm="12" md="8" lg="8" xl="8" xxl="8"
      class="settings-info pa-0 mt-3"
      v-if="enableNotification"
      >
        <NotificationSettings />
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center ma-auto" v-else-if="creative">
      <v-col cols="12" class="pb-12">
        <PageTitle />
      </v-col>
      <v-col>
        <v-col cols="2" class="px-0">
          <v-select
            class="notranslate lang-translator"
            v-model="currentLanguage"
            :items="$hyper.languages"
            item-text="name"
            item-value="code"
            label="Language"
            return-object
            solo
            @select="changeLanguage"
            flat
            style="min-width:150px; height:50px;"
            :menu-props="{ contentClass: 'notranslate' }"
            ref="languageSelector"
          >
            <template v-slot:item="{ item }">
              <v-list-item class="notranslate" @click="changeLanguage(item)">
                {{ item.name }}</v-list-item
              >
            </template>
          </v-select>
        </v-col>
        <CreativeProfileSettings />
      </v-col>
    </v-row>
    <v-row class="d-flex align-stretch change-password-form ma-auto" v-else>
      <v-col cols="12" class="pb-12">
        <PageTitle />
      </v-col>
      <v-col cols="2" class="mb-2">
        <v-select
          class="notranslate lang-translator"
          v-model="currentLanguage"
          :items="$hyper.languages"
          item-text="name"
          item-value="code"
          label="Language"
          return-object
          solo
          @select="changeLanguage"
          flat
          style="min-width:150px; height:50px;"
          :menu-props="{ contentClass: 'notranslate' }"
          ref="languageSelector"
        >
          <template v-slot:item="{ item }">
            <v-list-item class="notranslate" @click="changeLanguage(item)">
              {{ item.name }}</v-list-item
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" class="heading pl-5 mobile-password-heading font-h5">Password</v-col>
      <v-col cols="12" sm="6" md="5" class="mobile-padding">
        <v-form class="password-form ml-2" @submit="submitForm" ref="form">
          <BaseInput
            solo
            dense
            flat
            inputLabel="Current Password*"
            placeholder
            name="currentPassword"
            v-model.trim="currentPassword"
            :type="'password'"
            :status="errors('currentPassword').length ? 'error' : 'normal'"
            :error-messages="errors('currentPassword')"
            @input="$v.currentPassword.$touch()"
            @blur="$v.currentPassword.$touch()"
          />
          <BaseInput
            solo
            dense
            flat
            inputLabel="New Password*"
            placeholder
            name="password"
            v-model.trim="password"
            :type="'password'"
            :status="errors('password').length ? 'error' : 'normal'"
            :error-messages="errors('password')"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          />
          <BaseInput
            solo
            dense
            flat
            inputLabel="Confirm New Password*"
            placeholder
            name="confirmPassword"
            v-model.trim="confirmPassword"
            :type="'password'"
            :status="errors('confirmPassword').length ? 'error' : 'normal'"
            :error-messages="errors('confirmPassword')"
            @input="$v.confirmPassword.$touch()"
            @blur="$v.confirmPassword.$touch()"
          />
          <v-col class="pl-0 pt-0" md="3">
            <v-btn
              class="change-password-button btn-purple"
              type="submit"
              block
              :disabled="$v.$dirty && $v.$invalid"
              >Change Password</v-btn
            >
          </v-col>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="enableProducers">
      <v-col cols="12" md="6">
        <template>
          <v-col class="ma-0 d-flex align-center justify-center">
            <v-col cols="12" class="activity-settings pa-0">
              <NotificationSettings />
            </v-col>
          </v-col>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { required, sameAs } from 'vuelidate/lib/validators';
import BaseInput from '@/components/common/BaseInput';
import ClientProfileSettings from '@/components/profile/client/ProfileSettings';
import CreativeProfileSettings from '@/components/profile/creative/ProfileSettings';
import NotificationSettings from '@/components/common/NotificationSettings';
import OrganizationSettings from '@/components/organizations/Settings';
import PageTitle from '@/components/common/PageTitle';

export default {
  name: 'Settings',
  components: {
    BaseInput,
    ClientProfileSettings,
    CreativeProfileSettings,
    NotificationSettings,
    OrganizationSettings,
    PageTitle,
  },

  data() {
    return {
      profile: null,
      currentPassword: '',
      password: '',
      confirmPassword: '',
      activityAlerts: [],
      mode: 'normal',
      email: '',
      currentLanguage: null,
    };
  },
  methods: {
    ...mapActions('user', [
      'updateLanguage',
      'changePassword',
      'getActivityAlerts',
      'updateActivityAlert',
    ]),
    ...mapActions('profile', ['getProfile']),
    async changeLanguage(language, save = true) {
      this.currentLanguage = language;
      if (save) {
        await this.updateLanguage(language.code);
      }
      this.$hyper.translate(language.code, () => {
        localStorage.setItem('app_language', language.code);
      });
      this.$refs.languageSelector.blur();
    },
    isAlertEnabled(alert, type = 'email') {
      const userAlert = alert.userActivityAlert;
      if (userAlert) {
        return userAlert[type];
      }
      if (alert.defaultSetting === '3') {
        return true;
      }
      return type === 'email' ? alert.defaultSetting === '1' : alert.defaultSetting === '2';
    },
    updateAlert(event, alertId, type = 'email') {
      const payload = { activityAlertId: alertId };
      payload[type] = event;
      this.updateActivityAlert({ alerts: [payload] });
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { currentPassword, password, confirmPassword } = this;
        const resp = await this.changePassword({
          user: _.pickBy({
            currentPassword,
            password,
            confirmPassword,
          }),
        });
        if (resp.success) {
          this.$refs.form.reset();
          this.$v.$reset();
        }
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('profile', {
      clientProfile: 'clientProfile',
      creativeProfile: 'creativeProfile',
      executiveProfile: 'executiveProfile',
      producerProfile: 'producerProfile',
      editorProfile: 'editorProfile',
      communityManagerProfile: 'communityManagerProfile',
      agencyCustomer: 'agencyCustomer',
    }),
    enableNotification() {
      const { path } = this.$route;
      return (this.agency_customer || this.customer) && path === '/profile';
    },
    enableOrganizationProfile() {
      const { path } = this.$route;
      return this.agency_customer && path === '/profile';
    },
    enableCustomerProfile() {
      const { path } = this.$route;
      return this.customer && path === '/profile';
    },
    enableSettings() {
      const { path } = this.$route;
      return (this.agency_customer || this.customer) && path === '/settings';
    },
    firstName() {
      return this.profile.firstName;
    },
    lastName() {
      return this.profile.lastName;
    },
    number() {
      return this.profile.number;
    },
    enableProducers() {
      return _.includes(['executive', 'producer'], this.role);
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    agency_customer() {
      return _.includes(['agency_owner', 'agency_member'], this.role);
    },
    customer() {
      return _.includes(['client', 'member'], this.role);
    },
    creative() {
      return this.role === 'creative';
    },
    getProfileType() {
      switch (this.role) {
        case 'creative':
          return 'creativeProfile';
        case 'editor':
          return 'editorProfile';
        case 'producer':
          return 'producerProfile';
        case 'executive':
          return 'executiveProfile';
        case 'community_manager':
          return 'communityManagerProfile';
        case 'agency_owner':
        case 'agency_member':
          return 'agencyCustomer';
        default:
          return 'clientProfile';
      }
    },
    profileBeforeEdit() {
      return Object.assign({}, this.profile);
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'currentPassword':
            if (!this.$v.currentPassword.required) {
              errors.push('Please provide your current password.');
            }
            break;
          case 'password':
            if (!this.$v.password.required) {
              errors.push('Please provide your new password.');
            }
            // if (!this.$v.password.minLength) {
            //   errors.push('Password must be atleast 8 characters.');
            // }
            // if (!this.$v.password.strongPassword) {
            //   errors.push('Password must have atleast one number and one character.');
            // }
            break;
          case 'confirmPassword':
            if (!this.$v.confirmPassword.required) {
              errors.push('Please provide your confirm password.');
            }
            if (!this.$v.confirmPassword.sameAsPassword) {
              errors.push('Passwords do not match.');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  validations: {
    currentPassword: {
      required,
    },
    password: {
      required,
      // minLength: minLength(8),
      // strongPassword(currentPassword) {
      //   return (
      //     /[a-z]/.test(currentPassword)
      //     && /[0-9]/.test(currentPassword)
      //   );
      // },
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
    // firstName: {
    //   required,
    // },
    // lastName: {
    //   required,
    // },
    // number: {
    //   required,
    // },
    // email: {
    //   required,
    // },
  },
  async mounted() {
    const languageCode = localStorage.getItem('app_language') || 'en';
    const language = this.$hyper.getLanguageInfoFromCode(languageCode);
    this.currentLanguage = language;
    this.changeLanguage(languageCode, false);
    if (
      this.role !== 'editor'
      && this.role !== 'community_manager'
    ) {
      this.getActivityAlerts();
    }
    await this.getProfile(() => {
      const profileType = this.getProfileType;
      this.profile = this[profileType];
      this.email = this.profile.email;
    });
  },
};
</script>

<style lang="scss" scoped>
.settings {
  // padding-top: 14px;
  max-width: 1300px;
  margin: auto;
}
.settings-wrapper {
  max-width: 100%;
  ::v-deep .lang-translator.v-input .v-input__control .v-input__slot {
    border: 1px solid $lightSilver;
  }
}
.settings-info,
.activity-settings {
  background-color: #fff;
  border: 1px solid $lightSilver;
}
::v-deep .input-field-label {
  font-family: $fontFamily1;
  font-size: 14px !important;
  font-weight: normal;
  letter-spacing: -0.28px;
  margin-bottom: 3px;
}
::v-deep .v-messages {
  font-size: 14px !important;
  font-family: $fontFamily1;
  font-weight: normal;
}
.password-form ::v-deep .v-input__slot {
  border: 1px solid $silver !important;
}
.activity-title {
  color: $charcoalBlack;
  font-size: 13px !important;
  font-weight: bold;
}
.alert-title {
  font-size: 14px;
}
.alert {
  font-family: $fontFamily1;
  .v-list-item__title {
    font-size: 16px;
  }
  .v-list-item__subtitle {
    font-size: 13px;
    letter-spacing: -0.28px;
  }
}
@media (max-width: 600px) {
  // .settings-wrapper {
  //   padding-top: 60px !important;
  // }
  .mobile-password-heading {
    height: 35px;
    padding-top: 4px;
    padding-left: 10px !important;
  }
  .mobile-activity-heading {
    padding-top: 7px !important;
    padding-left: 7px !important;
  }
  .alert {
    padding-left: 10px !important;
    padding-top: 10px;
    .v-list-item__title {
      padding-top: 8px;
    }
    .v-list-item__subtitle {
      padding: 8px 0;
      white-space: break-spaces;
    }
  }
  .mobile-switch {
    display: flex;
    align-self: flex-start;
    padding-top: 8px;
  }
  .password-form {
    margin-left: 0px !important;
  }
  .mobile-padding {
    padding: 10px;
  }
  .alert-title {
    color: $charcoalBlack;
    font-size: 14px;
    font-weight: normal;
  }
  .change-password-button {
    min-width: 60px !important;
  }
  .heading {
    padding-left: 10px !important;
  }
}
</style>
