import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"489"},model:{value:(_vm.showConfirm),callback:function ($$v) {_vm.showConfirm=$$v},expression:"showConfirm"}},[_c(VCard,{staticClass:"affiliation pa-3",attrs:{"flat":""}},[_c(VRow,{staticClass:"title-header"},[_c(VCol,{staticClass:"d-flex align-center py-0 pl-0",attrs:{"cols":"10"}},[_c('span',{staticClass:"text modal-header-title"},[_vm._v(" Remove Affiliation ")])]),_c(VCol,{staticClass:"d-flex justify-end py-0 pr-0"},[_c(VIcon,{attrs:{"color":"darken-1"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("mdi-close")])],1)],1),_c(VRow,{staticClass:"info-text"},[_c(VCardText,{staticClass:"modal-heading pa-0"},[_c('p',[_vm._v(" "+_vm._s(("Are you sure you want to remove the affiliation between " + _vm.agencyName + " and " + _vm.selectedBusinessName + "?"))+" ")]),_c('p',[_vm._v(" Your shared workspace will become dormant. You’ll still be able to access and download any digital assets that were uploaded to that shared workspace. ")])])],1),_c(VRow,[_c(VCardActions,[_c(VBtn,{staticClass:"py-0",attrs:{"text":"","color":"primaryGray1"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"py-0 submit",attrs:{"type":"submit"},on:{"click":function($event){return _vm.$emit('remove')}}},[_vm._v(" Remove ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }