<template>
    <div class="reimbursement-container  mt-5 pa-5 pr-0 pb-0" v-if="businessData.length">
    <v-row class="headings pt-0 mr-5">
            <v-col class="py-0 pb-2">Business Name</v-col>
            <v-col class="py-0">Sweep Name</v-col>
            <v-col class="py-0">Payrate</v-col>
            <v-col class="py-0">Reimbursement</v-col>
          </v-row>
          <div
            class="mb-5"
            style="overflow-y: scroll;height: 300px;overflow-x: hidden;">
          <v-row
            class="business-data pa-3 pl-0 pr-0"
            v-for="item in businessData" :key="item">
            <v-col class="sweepname">
              {{ item.sweep.business.name }}
            </v-col>
            <v-col class="sweepname ml-4">{{ item.sweep.title }}</v-col>
            <v-col v-for="payout in payoutArray(item.sweep.sweepCreativePayout)"
            :key="payout" class="justify-left pl-10">
              <span>{{ payout.payout }}</span>
            </v-col>
            <v-col v-for="payout in payoutArray(item.sweep.sweepCreativePayout)"
              :key="payout" class="justify-center ml-12 pr-0">
              {{ payout.reimbursement ? payout.reimbursement : 0 }}
            </v-col>
          </v-row>
          </div>
    </div>
</template>

<script>
// import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      businessData: [],
      businessform: false,
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  methods: {
    ...mapActions('profile', ['getReimbursement']),
    payoutArray(val) {
      const arr = [];
      arr.push(val[0]);
      return arr;
    },
  },
  async mounted() {
    const data = await this.getReimbursement();
    this.businessData = data.sweepDetails.filter((x) => x.sweep !== null);
  },
};
</script>

<style scoped lang="scss">
.reimbursement-container {
  background-color: #fff;
  border: 1px solid $lightSilver;
.headings {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #b4b4b4;
      border-bottom: 1px solid #d8d8d8;
    }
    .sweepname {
        white-space: nowrap;
        width: 150px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }
    .business-data {
      border-bottom: 1px solid #D8D8D8;
    }
}
</style>
