import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.agencyBusinesses.length > 0)?_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.business.id)?_c(VRow,{staticClass:"affiliatedOrg ma-0"},[_c(VCol,{staticClass:"pt-0 pl-0",attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"12","xs":"12"}},[_c('div',{staticClass:"font-label d-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.reference === 'business' ? 'Organization' :'Businesses'))]),_c(VTooltip,{attrs:{"bottom":"","content-class":"affiliatedOrg-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"width":"16px","height":"16px","margin-left":"23px","cursor":"pointer"}},on),[_c(VImg,{attrs:{"src":_vm.infoSvg}})],1)]}}],null,false,2049368641)},[_c('span',[_vm._v(" As a business, you have access to the"),_c('br'),_vm._v(" organization's brand kit while the "),_c('br'),_vm._v(" organization has access to your business's"),_c('br'),_vm._v(" content plan, brand kit and library. ")])])],1),(_vm.reference === 'business')?_c('div',{staticClass:"sub-title"},[_vm._v(" Manage the organizations your business is affiliated with.")]):_vm._e(),(_vm.reference === 'organization')?_c('div',{staticClass:"sub-title"},[_vm._v(" Manage the businesses your organization is affiliated with.")]):_vm._e()]),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","xl":"9","offset-xl":"1","lg":"9","offset-lg":"1","md":"9","offset-md":"1","sm":"12","xs":"12"}},[_c('div',{staticClass:"affiliatedList"},[_c('div',{staticClass:"title-main"},[_vm._v(" "+_vm._s(_vm.reference === 'business' ? 'Organizations' :'Businesses'))]),_vm._l((_vm.agencyBusinesses),function(item){return _c(VRow,{key:item.id,staticClass:"list ma-auto"},[_c(VCol,{staticClass:"py-0 pl-0 pr-0 d-flex align-center",attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"6","xl":"6","xxl":"6"}},[_c('div',{staticClass:"ml-2 users-list"},[_c('img',{key:_vm.agency.id,staticStyle:{"height":"100%"},style:(("border-radius: " + (item.bannerImage ? '18px' : '8px') + ";")),attrs:{"src":_vm.getOptimizedS3ImageURL(
                    _vm.getItemAvatar(item),
                     { width: 250, height: 250 })}})]),_c('div',[_c('span',{staticClass:"list-item",staticStyle:{"padding-left":"5px","overflow-x":"auto"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"list-item ml-1",class:{'list-items': !_vm.$vuetify.breakpoint.xs}},[_vm._v(_vm._s(_vm.vertical(item))+" ")])])]),_c(VCol,{staticClass:"d-flex align-center justify-space-between vertical",attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"6","xl":"6","xxl":"6"}},[_c('div',{staticClass:"list-item",class:{'list-items2': !_vm.$vuetify.breakpoint.md && !_vm.$vuetify.breakpoint.lg &&
              !_vm.$vuetify.breakpoint.sm}},[_vm._v(_vm._s(_vm.vertical(item))+" ")]),(_vm.enableRemoveAffiliation)?_c('div',{staticClass:"d-flex justify-end card-body w-100"},[_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":"","content-class":"uploaded-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"menu-btn",attrs:{"dark":"","icon":"","height":"auto","width":"20"}},on),[_c(VIcon,{attrs:{"color":"black","dense":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VBtn,{staticClass:"w-100",attrs:{"text":"","color":"#FF0000"},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" Remove Affiliation ")])],1)],1)],1)],1)],1):_vm._e()])],1)})],2)])],1):_vm._e(),(_vm.confirm)?_c(VContainer,[_c('RemoveAffiliationPopup',{attrs:{"showConfirm":_vm.confirm,"agencyName":_vm.agency.name,"selectedBusinessName":_vm.selectedBusiness.name},on:{"close":_vm.close,"remove":_vm.unAffiliate}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }