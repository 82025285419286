<template>
  <div class="org-settings">
      <Loader v-if="fetching"/>
      <template v-else>
        <div class="font-h1" v-if="isBusiness">Business Settings</div>
        <div class="font-h1" v-if="isOrganization">Organization Settings</div>
        <div class="desc font-body" v-if="isBusiness">
          <div v-if="!memberText">
            Manage your business’s general information and user management.</div>
          <div v-else>Manage your business’s general information.</div>
        </div>
        <div class="desc font-body" v-if="isOrganization">
        <div v-if="!memberText">
          Manage your organization’s general information and user management.</div>
        <div v-else>Manage your organization’s general information.</div>
        </div>
        <v-row class="general-info ma-0">
          <v-col
            class="pl-0"
            cols="12"
            xxl="2"
            xl="2"
            lg="2"
            md="2"
            sm="2"
            xs="12">
            <div class="heading font-label">General Info</div>
            <div class="sub-title" v-if="isBusiness">
              Your Business basic contact information.
            </div>
            <div class="sub-title" v-if="isOrganization">
              Your Organization basic contact information.
            </div>
          </v-col>
          <v-col
          :class="$vuetify.breakpoint.smAndDown && 'pl-0'"
            cols="12"
            xxl="9"
            offset-xxl="1"
            xl="9"
            offset-xl="1"
            lg="9"
            offset-lg="1"
            md="9"
            offset-md="1"
            sm="12"
            xs="12">
            <div class="div-info d-flex justify-space-between">
              <div>
                <div
                class="label font-label">
                {{`${isOrganization ? 'Organization Logo' : 'Business Logo'}`}}</div>
                <div class="banner-img" style="margin-top: 13px;">
                  <v-img
                    class="profile-image"
                    :src="getOptimizedS3ImageURL(bannerLogo,
                      { width: 250, height: 250 })" alt="Profile"
                  />
                </div>
              </div>
              <div v-if="editAccess">
                <img
                  alt="edit"
                  :src="editIcon"
                  class="cursor-pointer"
                  @click="openModal"
                />
              </div>
            </div>
            <div class="div-info">
              <div
              class="font-label">
              {{`${isOrganization ? 'Organization Name' : 'Business Name'}`}}</div>
              <div class="label-text" :key="info.name">{{ info.name }}</div>
            </div>
            <div class="div-info">
              <div class="font-label">Business Vertical</div>
              <div
              class="label-text"
              :key="info.businessId">
              {{ business.businessType.name }}</div>
            </div>
            <div class="div-info">
              <div class="font-label">Website</div>
              <div class="label-text" :key="info.website">{{ info.website || '-' }}</div>
            </div>
            <div class="div-info">
              <div class="font-label">Phone Number</div>
              <div class="label-text" :key="info.phoneNumber">{{ info.phoneNumber || '-' }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row class="user-settings ma-0"
        v-if="business.id && enableUsers">
          <v-col
          class="pl-0"
            cols="12"
            xxl="2"
            xl="2"
            lg="2"
            md="2"
            sm="12"
            xs="12">
            <div class="heading font-label">User Management</div>
            <div class="sub-title">Manage members and their permissions.</div>
          </v-col>
          <v-col
            class="pa-0"
            cols="12"
            xxl="9"
            offset-xxl="1"
            xl="9"
            offset-xl="1"
            lg="9"
            offset-lg="1"
            md="9"
            offset-md="1"
            sm="12"
            xs="12">
            <OrganizationUsersList
            v-if="isOrganization"
            :selectedOrganizationId="selectedBusinessId"
            :selectedBusiness="business"
            :through="through" />
            <BusinessUsersList
            v-if="isBusiness"
            :selectedBusinessId="selectedBusinessId"
            :selectedBusiness="business"
            :through="through"
            />
          </v-col>
        </v-row>
        <template v-if="canAccessAffiliatedBusiness">
          <AffiliatedBusiness
          :reference="reference"
          :selectedBusinessId="selectedBusinessId"
          :through="through" />
        </template>
      </template>
    <v-container fluid v-if="generalInfoPopUp">
      <Modal persistent content-class="new-user-form" :modal="generalInfoPopUp" scrollable>
        <v-card tile flat outlined class="popup">
          <v-card-title class="modal-header-title">
            Edit General Info
            <v-spacer></v-spacer>
            <v-icon class="pr-1 cursor-pointer" @click="closeModal">mdi-close</v-icon>
          </v-card-title>
          <v-form @submit="submitForm">
            <div class="scrollable-div">
              <div class="organization-fields">
                <label class="font-label" v-if="isOrganization">Organization Logo</label>
                <label class="font-label" v-if="isBusiness">Business Logo</label>
                <div class="d-flex justify-center">
                  <div class="banner-img">
                    <v-img
                      class="profile-image"
                      :src="getOptimizedS3ImageURL(profilePicture,
                        { width: 250, height: 250 })" alt="Profile"
                    />
                  </div>
                </div>
                <div class="text-center pt-2">
                  <Upload
                    mode="justImage"
                    @change="setProfilePicture"
                    :acceptedFiles="'.jpg,.jpeg,.png'"
                  >
                    <span class="update-pic">Update</span>
                  </Upload>
                </div>
              </div>
              <div class="organization-fields">
                <label class="font-label" v-if="isOrganization">Organization Name*</label>
                <label class="font-label" v-if="isBusiness">Business Name*</label>
                <BaseInput
                  class="text-input"
                  solo
                  dense
                  flat
                  v-model.trim="name"
                  placeholder
                  name="name"
                  :status="errors('name').length ? 'error' : 'normal'"
                  :error-messages="errors('name')"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </div>
              <div class="organization-fields" >
                <label class="text-left font-label"> Business Vertical *</label>
                <v-select
                  solo
                  flat
                  class="type-selection mb-n3 notranslate"
                  name="businessTypeId"
                  :items="businessTypes"
                  item-text="name"
                  item-value="id"
                  v-model="businessTypeId"
                  :menu-props="{'content-class' : 'notranslate'}"
                  :status="errors('businessTypeId').length ? 'error': 'normal'"
                  :error-messages="errors('businessTypeId')"
                  @input="$v.businessTypeId.$touch()"
                  @blur="$v.businessTypeId.$touch()"
                  :disabled="true"
                ></v-select>
              </div>
              <div class="organization-fields">
                <label class="font-label">Website{{isBusiness ? '*' : ''}}</label>
                <BaseInput
                  class="text-input"
                  solo
                  dense
                  flat
                  v-model.trim="website"
                  placeholder
                  name="website"
                  :status="errors('website').length ? 'error' : 'normal'"
                  :error-messages="errors('website')"
                  @input="$v.website.$touch()"
                  @blur="$v.website.$touch()"
                />
              </div>
              <div class="organization-fields">
                <label class="font-label" v-if="isOrganization">
                  Organization Phone Number</label>
                <label class="font-label" v-if="isBusiness">Business Phone Number</label>
                <BaseInput
                :style="`width: ${$vuetify.breakpoint.smAndUp ? '225px' : '100%'}`"
                  class="text-input phoneNumber"
                  solo
                  dense
                  flat
                  type="tel"
                  v-mask="'###-###-####'"
                  v-model.trim="phoneNumber"
                  name="phoneNumber"
                  :error-messages="errors('phoneNumber')"
                  @input="$v.phoneNumber.$touch()"
                  @blur="$v.phoneNumber.$touch()"
                />
              </div>
            </div>
            <v-card-actions>
              <v-btn text class="py-0" color="primaryGray1" @click="closeModal">
                Cancel
              </v-btn>
              <v-btn
                class="py-0 submit btn-purple"
                type="submit"
                :loading="loading"
                :disabled="$v.$dirty && $v.$invalid"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </Modal>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  required,
  requiredIf,
  maxLength,
  minLength,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import BaseInput from '@/components/common/BaseInput';
import Upload from '@/components/common/Upload';
import OrganizationUsersList from '@/components/organizations/businesses/UsersList';
import BusinessUsersList from '@/components/organizations/businesses/business/UsersList';
import AffiliatedBusiness from '@/components/organizations/AffiliatedList';
import Modal from '@/components/common/Modal';
import edit from '@/assets/svg/theme/edit.svg';
import bannerPlaceHolder from '@/assets/svg/banner-default.svg';
import { getOptimizedS3ImageURL, url } from '@/helpers/';
import Loader from '@/components/common/Loader';

export default {
  name: 'OrganizationSettings',
  directives: {
    mask,
  },
  components: {
    AffiliatedBusiness,
    OrganizationUsersList,
    BusinessUsersList,
    Modal,
    BaseInput,
    Upload,
    Loader,
  },
  methods: {
    ...mapActions('brandProfile', ['getBrandProfile']),
    ...mapActions('organizations', ['updateBusinessSettings']),
    ...mapActions('business', ['getBusiness']),
    ...mapActions('profile', ['getBusinessTypes']),
    getOptimizedS3ImageURL,
    openModal() {
      this.generalInfoPopUp = !this.generalInfoPopUp;
      this.$v.$reset();
      this.bannerImage = this.info.bannerImage;
      this.name = this.info.name;
      this.website = this.info.website;
      this.phoneNumber = this.info.phoneNumber;
      this.businessTypeId = this.info.businessTypeId;
    },
    closeModal() {
      this.generalInfoPopUp = !this.generalInfoPopUp;
    },
    setProfilePicture(file) {
      this.bannerImage = file;
    },
    async submitForm(e) {
      this.loading = true;
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const mobile = !_.isEmpty(this.phoneNumber) ? _.get(this, 'phoneNumber') : '';
        const website = !_.isEmpty(this.website) ? _.get(this, 'website') : '';
        const apiCall = 'updateBusinessSettings';
        const formData = new FormData();
        formData.append('bannerImage', this.bannerImage);
        formData.append('name', this.name);
        formData.append('website', website);
        formData.append('mobile', mobile);
        formData.append('businessId', this.business.id);
        formData.append('businessTypeId', this.businessTypeId);
        const response = await this[apiCall](formData);
        if (response.success) {
          this.$v.$reset();
          this.closeModal();
        }
      }
      this.loading = false;
    },
  },
  data() {
    return {
      fetching: false,
      loading: false,
      info: {
        bannerImage: null,
        name: '',
        website: '',
        phoneNumber: '',
        businessTypeId: '',
      },
      bannerImage: null,
      name: '',
      website: '',
      phoneNumber: '',
      businessTypeId: '',
      avatar: null,
      generalInfoPopUp: false,
      addUserPopUp: false,
    };
  },
  props: {
    through: {
      type: String,
      default: '',
    },
    reference: {
      type: String,
      default: 'business',
    },
    selectedBusinessId: {
      type: String,
      default: '',
    },
  },
  watch: {
    selectedBusinessId(id) {
      this.getBrandProfile({ businessId: id, reference: 'business' });
      this.getBusiness(id);
    },
    business(data) {
      this.info.bannerImage = data.bannerImage;
      this.info.name = data.name;
      this.info.businessTypeId = JSON.parse(data.businessTypeId);
      this.bannerImage = data.bannerImage;
      this.name = data.name;
      this.businessTypeId = JSON.parse(data.businessTypeId);
    },
    brandProfile(kit) {
      this.info.website = kit.website !== 'null' ? _.get(kit, 'website', '') : '';
      this.info.phoneNumber = kit.mobile !== 'null' ? _.get(kit, 'mobile', '') : '';
      this.website = kit.website !== 'null' ? _.get(kit, 'website', '') : '';
      this.phoneNumber = kit.mobile !== 'null' ? _.get(kit, 'mobile', '') : '';
    },
  },
  computed: {
    ...mapGetters('profile', ['businessTypes']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('business', ['business']),
    ...mapGetters('brandProfile', ['brandProfile']),
    canAccessAffiliatedBusiness() {
      if (['admin', 'executive'].includes(this.role)) {
        if (this.through === 'business') {
          return true;
        }
        return false;
      }
      return true;
    },
    enableUsers() {
      return (['admin', 'executive', 'agency_owner', 'client', 'producer']).includes(this.role);
    },
    memberText() {
      return (['agency_member', 'member']).includes(this.role);
    },
    bannerIcon() {
      return bannerPlaceHolder;
    },
    profilePicture() {
      if (this.bannerImage === null || this.bannerImage === 'null') {
        return bannerPlaceHolder;
      }
      if (this.bannerImage instanceof File) {
        return URL.createObjectURL(this.bannerImage);
      }
      return this.bannerImage;
    },
    errors() {
      const type = this.isOrganization ? 'organization' : 'business';
      const capitalString = this.isOrganization ? 'Organization' : 'Business';
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.name.required) {
              errors.push(`Please provide ${type} name`);
            }
            if (!this.$v.name.maxLength) {
              errors.push(`${capitalString} name must be less than 160 characters length`);
            }
            break;
          case 'businessTypeId':
            if (!this.$v.businessTypeId.required) { errors.push('Please select business vertical'); }
            break;
          case 'website':
            if (!this.$v.website.required) {
              errors.push(`Please provide your ${type} website.`);
            }
            if (!this.$v.website.url) {
              errors.push('Please provide valid url.');
            }
            break;
          case 'phoneNumber':
            if (!this.$v.phoneNumber.minLength) {
              errors.push('Please provide valid phone number');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    bannerLogo() {
      if (this.info.bannerImage) {
        return this.info.bannerImage;
      }
      return bannerPlaceHolder;
    },
    editIcon() {
      return edit;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    editAccess() {
      if (_.includes(['agency_owner', 'client', 'admin', 'executive'], this.role)) {
        return true;
      }
      return this.business.assigned;
    },
    isBusiness() {
      return this.reference === 'business';
    },
    isOrganization() {
      return this.reference === 'organization';
    },
  },
  async mounted() {
    this.fetching = true;
    await this.getBrandProfile({ businessId: this.selectedBusinessId, reference: 'business' });
    await this.getBusiness(this.selectedBusinessId);
    await this.getBusinessTypes();
    this.fetching = false;
  },
  validations: {
    phoneNumber: {
      minLength: minLength(12),
    },
    name: {
      required,
      maxLength: maxLength(160),
    },
    businessTypeId: { required },
    website: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        return this.isBusiness;
      }),
      url,
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-img {
  width: 100px;
  height: 100px;
  .profile-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
.popup {
  font-family: $fontFamily1;
  border: none !important;
  .v-card__title {
    border-bottom: 1px solid #d1d1d1;
    padding-left: 21px;
    padding-right: 21px;
  }
  .v-form {
    .scrollable-div {
      padding: 13px 21px 0 21px;
    }
    .organization-fields {
      .update-pic {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $secondary1;
        cursor: pointer;
      }
      ::v-deep .normalState .v-input__slot {
        border: 1px solid #E0E0E0 !important;
      }
      ::v-deep .nullState .v-input__slot {
        border: 1px solid #E0E0E0 !important;
      }
    }
    .v-card__actions {
      padding: 0 21px 13px 21px;
      justify-content: flex-end;
      .v-btn {
        box-shadow: none;
        border-radius: 30px;
        font-weight: 400;
        font-size: 16px;
        color: $secondary3;
        line-height: 24px;
      }
      .submit {
        font-weight: 700;
        line-height: 28px;
        width: 106px;
        margin-left: 12px;
      }
    }
  }
}

.org-settings {
  background-color: #fff;
  width: 100%;
  height:70vh;
  padding: 12px;
  .desc {
    margin-top: 17px;
    margin-bottom: 40px;
  }
  .general-info {
    .div-info {
      margin-bottom: 30px;
      .label-text {
        margin-top: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $charcoalBlack;
      }
    }
  }
  .general-info,
  .user-settings {
    border-top: 1px solid #d8d8d8;
    padding-top: 25px;
    padding-bottom: 40px;
    .sub-title {
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: $secondary3;
      margin-top: 10px;
    }
  }
}
.organization-fields {
  ::v-deep .v-input {
    margin-top: 6px;
    .v-input__control {
      .v-select__selection, input {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4F4F4F !important;
      }
    }
  }
  ::v-deep .v-input__slot {
    min-height: 40px;
  }
  ::v-deep .type-selection {
    .v-input__slot {
      border: solid 1px #e6e8ed;
    }
  }
  ::v-deep .type-selection.error--text .v-input__slot {
    border: solid 1px #ff0808;
  }
}
</style>
