<template>
  <div>
    <v-row class="ma-0 pa-2">
      <v-col class="d-flex align-center pb-1" cols=10>
        <span class="text-capitalize mr-1 modal-header-title"> Change Password </span>
      </v-col>
      <v-col class="d-flex justify-end pb-1">
        <v-icon color="darken-1" @click="$emit('close-form')">mdi-close</v-icon>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="d-flex align-stretch ma-0">
      <v-col cols="12" md="8" class="mobile-padding pb-0 pt-2">
        <v-form class="password-form ml-2" ref="form">
          <BaseInput
            solo
            dense
            flat
            inputLabel="Current Password*"
            placeholder
            name="currentPassword"
            v-model.trim="currentPassword"
            :type="'password'"
            :status="errors('currentPassword').length ? 'error': 'normal'"
            :error-messages="errors('currentPassword')"
            @input="$v.currentPassword.$touch()"
            @blur="$v.currentPassword.$touch()"
          />
          <BaseInput
            solo
            dense
            flat
            inputLabel="New Password*"
            placeholder
            name="password"
            v-model.trim="password"
            :type="'password'"
            :status="errors('password').length ? 'error': 'normal'"
            :error-messages="errors('password')"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          />
          <BaseInput
            solo
            dense
            flat
            inputLabel="Confirm New Password*"
            placeholder
            name="confirmPassword"
            v-model.trim="confirmPassword"
            :type="'password'"
            :status="errors('confirmPassword').length ? 'error': 'normal'"
            :error-messages="errors('confirmPassword')"
            @input="$v.confirmPassword.$touch()"
            @blur="$v.confirmPassword.$touch()"
          />
        </v-form>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols=10 class="pa-0">
      </v-col>
        <v-col class="d-flex justify-end pa-0 pb-2" cols="2">
        <v-btn
          @click="submitForm"
          type="submit"
          class="btn-purple ma-2"
          block
          :disabled="$v.$dirty && $v.$invalid"
        >Change Password</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  required, sameAs,
} from 'vuelidate/lib/validators';
import _ from 'lodash';
import { mapActions } from 'vuex';

import BaseInput from '@/components/common/BaseInput';


export default {
  components: {
    BaseInput,
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'currentPassword':
            if (!this.$v.currentPassword.required) {
              errors.push('Please provide your current password.');
            }
            break;
          case 'password':
            if (!this.$v.password.required) {
              errors.push('Please provide your new password.');
            }
            if (!this.$v.password.minLength) {
              errors.push('Password must be atleast 8 characters.');
            }
            // if (!this.$v.password.strongPassword) {
            //   errors.push('Password must have atleast one number and one character.');
            // }
            break;
          case 'confirmPassword':
            if (!this.$v.confirmPassword.required) {
              errors.push('Please provide your confirm password.');
            }
            if (!this.$v.confirmPassword.sameAsPassword) {
              errors.push('Passwords do not match.');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    };
  },
  methods: {
    ...mapActions('user', ['changePassword']),
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { currentPassword, password, confirmPassword } = this;
        const resp = await this.changePassword({
          user: _.pickBy({
            currentPassword, password, confirmPassword,
          }),
        });
        if (resp.success) {
          this.$emit('close-form');
        }
      }
    },
  },
  validations: {
    currentPassword: {
      required,
    },
    password: {
      required,
      // minLength: minLength(8),
      // strongPassword(currentPassword) {
      //   return (
      //     /[a-z]/.test(currentPassword)
      //     && /[0-9]/.test(currentPassword)
      //   );
      // },
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>
