<template>
<!-- eslint-disable -->
  <v-row class="ma-0 d-flex align-center">
    <v-col class="pa-0">
     <v-row class="ma-0 px-4 py-2">
          <v-col class="d-flex info-title justify-start info-title">
            YOUR AVAILABILITY
          </v-col>
      </v-row>
      <template >
        <v-divider color="#c4c4c4"> </v-divider>
        <div class="pb-5 pt-3 mobile-card">
         <div class="input-content">
              <v-row class="sweepDays">
                <v-expansion-panels
                  class="day"
                  multiple
                  v-model="panel[i]"
                  v-for="(item, i) in availabilityData"
                  :key="i"
                >
                  <v-col col="4" class="days-list">
                    <v-expansion-panel :ref="`day-${item.day}`">
                      <v-expansion-panel-header
                        @click="(e) => toggleExpansion(item, e)"
                        class="panel-head"
                      >
                        {{ days[item.day] }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="options-list">
                          <v-checkbox
                            class="skill-selection-checkbox"
                            v-model="item.morning"
                            label="Morning (8am-12pm)"
                            hide-details
                            color="secondary1"
                          ></v-checkbox>
                          <v-checkbox
                            class="skill-selection-checkbox"
                            v-model="item.afternoon"
                            label="Afternoon (12pm-5pm)"
                            hide-details
                            color="secondary1"
                          ></v-checkbox>
                          <v-checkbox
                            class="skill-selection-checkbox"
                            v-model="item.evening"
                            label="Evening (5pm-10pm)"
                            hide-details
                            color="secondary1"
                          ></v-checkbox>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-col>
                </v-expansion-panels>
              </v-row>
            </div>

         <div class="info-scoped">
                            Morning extends from 8am-12pm CST, afternoon from 12pm-5pm CST, and evenings from 5pm-10pm CST. 
                       </div>
        </div>
      </template>

      <template>
        <v-snackbar v-model="alert" color="#ff0808" multi-line top right :timeout="timeout">
          Please check for errors
          <v-icon dark right @click="alert = false">
            mdi-close-circle
          </v-icon>
        </v-snackbar>
      </template>
      <template v-if="userStatusConfirm">
        <v-dialog v-model="userStatusConfirm" max-width="680">
          <v-card class="modal-card pa-4" flat>
            <v-card-text class="pa-2 black--text fontSize font-family-2 card-text">
              Are you sure you want to update your status to inactive? You will not receive Content
              Sweep invites in inactive status.
            </v-card-text>
            <v-card-actions class="pa-0">
              <v-spacer></v-spacer>
              <v-btn
              text
              color="primaryGray1"
              @click="userStatusConfirm = false"
              class="py-0 status-btn">
                Cancel
              </v-btn>
              <v-btn text color="info" @click="updateData" class="py-0 pr-2 status-btn">
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-col v-if="modified" class="action-btn pr-7 py-0 pb-5 d-flex justify-end">
        <v-btn
        text
        class="mr-4 cancel-btn"
        @click="cancel">Cancel</v-btn>
        <v-btn
        text
        class="cancel-btn font-family-2  btn-purple"
        :loading="loading" @click="saveData">Save</v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'ProfileAvailability',
  props: {
    editConfirm: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    userDetails: {
      handler(val) {
        this.availability = val.availability;
      },
      deep: true,
    },
  },
  data() {
    return {
      timeFormat: 'HH:mm:ss',
      loading: false,
      isBeforeEdit: [],
      time: null,
      timeout: 4000,
      availability: '1',
      panel: [[0], [0], [0], [0], [0], [0], [0]],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      userStatusConfirm: false,
      alert: false,
      timePickerErr: false,
      availabilityData: [],
    };
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    formattedFromTime() {
      return (dayFromTime) => this.dayTime(dayFromTime);
    },
    formattedToTime() {
      return (daytoTime) => this.dayTime(daytoTime);
    },
    modified() {
      return (
        !_.isEqual(this.isBeforeEdit, this.availabilityData)
      );
    },
    items() {
      const userData = this.userDetails;
      const userDays = _.sortBy(userData.days, ['day']);
      const week = ['0', '1', '2', '3', '4', '5', '6'];
      return _.map(week, (day) => ({
        id: _.get(_.find(userDays, ['day', day]), 'id', day),
        day: this.getDay(day),
        from: _.get(_.find(userDays, ['day', day]), 'from', false),
        to: _.get(_.find(userDays, ['day', day]), 'to', false),
        userId: _.get(_.find(userDays, ['day', day]), 'userId'),
        fromTime: _.get(_.find(userDays, ['day', day]), 'fromTime', '08:00:00'),
        toTime: _.get(_.find(userDays, ['day', day]), 'toTime', '20:00:00'),
        status: this.getStatus(_.get(_.find(userDays, ['day', day]), 'status', '1')),
      }));
    },
    errors() {
      return (dayinfo) => {
        const errors = [];
        switch (dayinfo.day) {
          case 'Sunday':
          case 'Monday':
          case 'Tuesday':
          case 'Wednesday':
          case 'Thursday':
          case 'Friday':
          case 'Saturday':
            if (dayinfo.fromTime) {
              if (moment(dayinfo.fromTime, ['HH:mm']).isAfter(moment(dayinfo.toTime, ['HH:mm']))) {
                errors.push('Please select a time that is not in the past');
              }
              if (moment(dayinfo.fromTime, ['HH:mm']).isSame(moment(dayinfo.toTime, ['HH:mm']))) {
                errors.push('Start time and End time cannot be same.');
              }
            } else if (dayinfo.toTime) {
              if (moment(dayinfo.toTime, ['HH:mm']).isBefore(moment(dayinfo.fromTime, ['HH:mm']))) {
                errors.push('Please select a time that is not in the past');
              }
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    anyError() {
      return (_.find(this.weekDays, (dayinfo) => this.errors(dayinfo).length)
      || this.timePickerErr);
    },
    dayTime() {
      return (time) => {
        if (time) {
          return moment(time, ['HH:mm:ss']).format('h:mm A');
        }
        return null;
      };
    },
  },
  methods: {
    ...mapActions('user', ['getCreativeAvailability', 'updateCreativeAvailability']),
    ...mapActions('profile', ['creativeProfileUpdate', 'getProfile']),
    dayToggle(status) {
      return status === true ? 'Open' : 'Closed';
    },
    timeUpdate(event, index, field) {
      this.weekDays[index][field] = event;
    },
    onError(val, index, field) {
      this.timePickerErr = val;
      this.weekDays[index][field] = val;
    },
    async updateData() {
      this.loading = true;
      const result = await this.updateCreativeAvailability({
        availabilityData: this.availabilityData,
      });
      if (result.success) {
        await this.getProfile();
        this.availabilityData = this.userDetails.days;
        this.isBeforeEdit = _.cloneDeep(this.userDetails.days);
      }
      this.loading = false;
    },
    getStatus(status) {
      return status === '1';
    },
    saveData() {
      this.updateData();
    },
    getDay(day) {
      switch (day) {
        case '0':
          return 'Sunday';
        case '1':
          return 'Monday';
        case '2':
          return 'Tuesday';
        case '3':
          return 'Wednesday';
        case '4':
          return 'Thursday';
        case '5':
          return 'Friday';
        default:
          return 'Saturday';
      }
    },
    disableStatus(dayStatus) {
      if (this.availability === '1') {
        if (dayStatus === true) {
          return false;
        }
        return true;
      }
      return true;
    },
    updatePanel() {
      this.availabilityData.map((item) => {
        const availabilityValues = [
          item.morning,
          item.afternoon,
          item.evening,
        ];
        const someAreTruthy = availabilityValues.some((value) => value);
        // eslint-disable-next-line
        if (item.day == '0') {
          this.panel[6] = !someAreTruthy ? [1] : [0];
        } else {
          this.panel[item.day - 1] = !someAreTruthy ? [1] : [0];
        }
        return this.panel;
      });
    },
    mainToggle(event) {
      if (this.anyError && event === '2') {
        this.alert = true;
        this.$nextTick(() => {
          this.availability = '1';
        });
      } else {
        this.availability = event;
      }
    },
    updateCurrentState() {
      this.isBeforeEdit = _.cloneDeep(this.items);
      this.weekDays = this.items;
      this.availability = this.userDetails.availability;
    },
    cancel() {
      this.availabilityData = _.cloneDeep(this.isBeforeEdit);
    },
    disableState() {
      return this.availability === '2';
    },
    updateAlert(event, index) {
      this.weekDays[index].status = event;
      if (!event) {
        if (this.errors(this.weekDays[index]).length > 0) {
          this.weekDays[index].fromTime = this.isBeforeEdit[index].fromTime;
          this.weekDays[index].toTime = this.isBeforeEdit[index].toTime;
        }
      }
    },
    toggleExpansion(item, e) {
      const refKey = `day-${item.day}`;
      // eslint-disable-next-line
      const [ expansionPanel ] = this.$refs[refKey];
      const availabilityValues = [
        item.morning,
        item.afternoon,
        item.evening,
      ];
      const someAreTruthy = availabilityValues.some((value) => value);
      if (someAreTruthy && expansionPanel.isActive) {
        e.stopPropagation();
        e.preventDefault();
        expansionPanel.toggle();
      }
      if (!someAreTruthy && !expansionPanel.isActive) {
        // eslint-disable-next-line
        const objIndex = this.availabilityData.findIndex((obj => obj.day == item.day));
        this.availabilityData[objIndex].morning = true;
        this.availabilityData[objIndex].afternoon = true;
        this.availabilityData[objIndex].evening = true;
      }
    },
  },
  async mounted() {
    await this.getCreativeAvailability();
    this.updateCurrentState();
    this.availabilityData = this.userDetails.days;
    this.isBeforeEdit = _.cloneDeep(this.userDetails.days);
    this.updatePanel();
  },
};
</script>
<style lang="scss" scoped>
.info-scoped {
  color: black;
  font-family: $fontFamily1;
  font-size: 12px;
  font-style: italic;
}
.skill-selection-checkbox {
  font-size: 14px !important;
  font-weight: 400px;
  width: fit-content;
}
.panel-head {
   font-size: 16px !important;
   font-weight: 400px;
}
.info-title {
  color: #262626;
  font-size: 13px !important;
  font-weight: bold;
}
.mobile-card {
  padding: 10px;
}
.day {
  width: 50%;
}
.errorText {
  font-size: 13px;
}
.dayTitle {
  height: 20px;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $charcoalBlack;
}
::v-deep .theme--light.v-label {
  height: 17px;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $charcoalBlack;
}
.status-btn {
  font-size: 16px;
}
.availability-title {
  color: $charcoalBlack;
  font-size: 13px !important;
  font-weight: bold;
}
.input-field {
  border-radius: 3px;
  background-color: #fff;
}
.to-text {
  height: 14px;
  font-family: $fontFamily1;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
}
.save-btn {
  width: 59px;
  margin: 0 0 0 15px;
  padding: 7px 10px 7px 9px !important;
}
.save-text {
  width: 40px;
  height: 16px;
  font-family: $fontFamily1;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.cancel-btn {
  width: 42px;
  height: 17px;
  flex-grow: 0;
  font-family: $fontFamily1;
  font-size: 14px;
  text-align: left;
}
.text-caption {
  line-height: 1.4;
  font-family: $fontFamily1;
  font-size: 12px;
  color: $darkGray;
}
.availability-sub-title {
  flex-grow: 0;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: $charcoalBlack;
}
.alert-subtitle {
  font-size: 12px !important;
  color: $charcoalBlack;
}
::v-deep .v-icon.v-icon {
  font-size: 16px;
  padding-right: 3px;
}
::v-deep .v-text-field input {
  height: 16px;
  padding: 1px 0px 1px 8px;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4f4f4f;
}
::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0px !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 0) and (orientation: landscape) {
  .input-field {
    width: 120px;
  }
  .dayTitle {
    max-width: 175px;
  }
  .fromTime {
    padding-left: 8px;
  }
  .mobile-error {
    justify-content: start !important;
    padding-left: 9px !important;
  }
}
@media only screen and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 0)
and (orientation: landscape) {
  .horizontal-line {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 0) and (orientation: portrait) {
  .horizontal-line {
    display: none;
  }
  .fromTime {
    padding-left: 8px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 6666px)
and (-webkit-min-device-pixel-ratio: 0) and (orientation: landscape) {
  .horizontal-line {
    display: none;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 6666px)
and (-webkit-min-device-pixel-ratio: 0) and (orientation: portrait) {
  .horizontal-line {
    display: none;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 2800px)
and (-webkit-min-device-pixel-ratio: 0) and (orientation: landscape) {
  .horizontal-line {
    display: none;
  }
  ::v-deep .v-icon.v-icon {
  font-size: 16px;
  padding-left: 0px;
  }
  .to-text {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 0) and (orientation: landscape) {
  .fromTime {
    padding-left: 8px;
  }
}
@media (min-width: 1024px)
and (-webkit-min-device-pixel-ratio: 0)
and (orientation: portrait) {
  .mobile-card {
    padding-top: 0px !important;
  }
  .mobile-error {
    justify-content: start !important;
    padding-left: 9px !important;
  }
}
@media (max-width: 600px) and (-webkit-min-device-pixel-ratio: 0)
and (orientation: portrait) {
  .mobile-error {
    justify-content: start !important;
    padding-left: 9px !important;
  }
  .mobile-card {
    padding: 0px !important;
  }
  .mobile-activity-heading {
    padding: 7px 12px 12px 6px;
  }
  .fromTime {
    padding-left: 8px;
  }
  .row-height {
    padding-bottom: 20px !important;
  }
}
</style>
