<template>
  <v-container fluid class="pa-0" v-if="agencyBusinesses.length > 0">
    <v-row class="affiliatedOrg ma-0"
      v-if="business.id">
      <v-col
      cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        xs="12"
        class="pt-0 pl-0">
        <div class="font-label d-flex align-center">
          <span>{{ reference === 'business' ? 'Organization' :'Businesses' }}</span>
          <v-tooltip
            bottom
            content-class="affiliatedOrg-tooltip"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" style="width: 16px; height: 16px; margin-left: 23px; cursor: pointer;">
                <v-img :src="infoSvg" />
              </div>
            </template>
            <span>
              As a business, you have access to the<br/> organization's
              brand kit while the <br/> organization has access to your business's<br/>
              content plan, brand kit and library.
            </span>
          </v-tooltip>
        </div>
        <div class="sub-title" v-if="reference === 'business'">
          Manage the organizations your business is affiliated with.</div>
        <div class="sub-title" v-if="reference === 'organization'">
          Manage the businesses your organization is affiliated with.</div>
      </v-col>
      <v-col class="pa-0"
      cols="12"
        xl="9"
        offset-xl="1"
        lg="9"
        offset-lg="1"
        md="9"
        offset-md="1"
        sm="12"
        xs="12">
        <div class="affiliatedList">
          <div class="title-main">
            {{ reference === 'business' ? 'Organizations' :'Businesses' }}</div>
          <v-row class="list ma-auto" v-for="item in agencyBusinesses" :key="item.id">
            <v-col
              cols="12"
              xs="12" sm="6" md="6" lg="6" xl="6" xxl="6"
              class="py-0 pl-0 pr-0 d-flex align-center">
                <div class="ml-2 users-list">
                    <img
                    :src="getOptimizedS3ImageURL(
                      getItemAvatar(item),
                       { width: 250, height: 250 })"
                    :key="agency.id"
                    style="height: 100%;"
                    :style="`border-radius: ${item.bannerImage ? '18px' : '8px'};`"/>
                </div>
                <div>
                <span class="list-item" style="padding-left: 5px;overflow-x: auto;">
                  {{ item.name }}
                </span>
              <div class="list-item ml-1"
              :class="{'list-items': !$vuetify.breakpoint.xs}">{{ vertical(item) }}
              </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              xs="12" sm="6" md="6" lg="6" xl="6" xxl="6"
              class="d-flex align-center justify-space-between vertical">
              <div class="list-item"
              :class="{'list-items2': !$vuetify.breakpoint.md && !$vuetify.breakpoint.lg &&
                !$vuetify.breakpoint.sm}"
              >{{ vertical(item) }}
              </div>
              <div class="d-flex justify-end card-body w-100" v-if="enableRemoveAffiliation">
                <v-menu bottom left offset-y offset-x content-class="uploaded-content">
                  <template v-slot:activator="{ on }">
                    <v-btn dark icon height="auto" width="20" v-on="on" class="menu-btn">
                      <v-icon color="black" dense>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn text class="w-100" color="#FF0000" @click="openModal(item)">
                          Remove Affiliation
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-container v-if="confirm">
      <RemoveAffiliationPopup
      :showConfirm="confirm"
      @close="close"
      @remove="unAffiliate"
      :agencyName="agency.name"
      :selectedBusinessName="selectedBusiness.name" />
    </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import RemoveAffiliationPopup from '@/components/organizations/RemoveAffiliationPopup';
import organizationsIcon from '@/assets/svg/organisations.svg';
import { getOptimizedS3ImageURL } from '@/helpers/';
import infoIcon from '@/assets/svg/info.svg';
import businessesIcon from '@/assets/svg/businesses.svg';

export default {
  name: 'AffiliatedList',
  components: {
    RemoveAffiliationPopup,
  },
  props: {
    through: {
      type: String,
      default: '',
    },
    reference: {
      type: String,
      default: 'business',
    },
    selectedBusinessId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    async selectedBusinessId(id) {
      if (this.reference === 'organization') {
        await this.getAgencyBusinesses({
          businessId: id,
          status: 1,
        });
      }
      if (this.reference === 'business') {
        await this.getBusinessAgencies({
          businessId: id,
        });
      }
    },
  },
  data() {
    return {
      selectedBusiness: {},
      confirm: false,
    };
  },
  methods: {
    ...mapActions('organizations',
      ['removeAffiliation', 'getAgencyBusinesses', 'getBusinessAgencies', 'getSingleAgency']),
    getOptimizedS3ImageURL,
    openModal(item) {
      this.selectedBusiness = item;
      this.confirm = true;
    },
    close() {
      this.confirm = false;
    },
    getItemAvatar(item) {
      const fallbackIcon = this.reference !== 'business'
        ? businessesIcon : organizationsIcon;
      return item.bannerImage || fallbackIcon;
    },
    async unAffiliate() {
      const result = await this.removeAffiliation({
        businessId: this.reference === 'organization' ? _.get(this.agency, 'id') : JSON.parse(this.selectedBusiness.id),
        affiliatedBusinessId: this.reference === 'organization' ? this.selectedBusiness.id : this.business.id,
        reference: this.reference,
      });
      if (result.success) {
        this.confirm = false;
      }
    },
  },
  computed: {
    ...mapGetters('organizations', ['agency', 'agencyBusinesses']),
    ...mapGetters('business', ['business']),
    ...mapGetters('profile', ['businessTypes']),
    ...mapGetters('user', ['userDetails']),
    infoSvg() {
      return infoIcon;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    client() {
      return ['agency_owner'].includes(this.role);
    },
    businessId() {
      return _.get(this.userDetails, 'currentBusinessId');
    },
    enableRemoveAffiliation() {
      if (this.through === 'business') {
        return false;
      }
      return (['admin', 'executive', 'agency_owner', 'client']).includes(this.role);
    },
    vertical() {
      return (business) => _.get(_.filter(this.businessTypes, { id: business.businessTypeId }), '[0].name');
    },
  },
  async mounted() {
    if (this.reference === 'organization') {
      if (this.client) {
        await this.getSingleAgency(this.businessId);
      }
      await this.getAgencyBusinesses({
        businessId: this.selectedBusinessId,
        status: 1,
      });
    }
    if (this.reference === 'business') {
      await this.getBusinessAgencies({
        businessId: this.selectedBusinessId,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.list-items {
  display: none;
}
.list-items2 {
  display: none;
}
.users-list {
  width: 80px !important;
  height: 60px !important;
}
.affiliatedOrg-tooltip {
  opacity: 1 !important;
  background: #FFFFFF !important;
  border: 1px solid #E0E0E0 !important;
  border-radius: 10px !important;
  font-family: $fontFamily1;
  padding: 16px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $secondary3;
}
  .affiliatedOrg {
    border-top: 1px solid #d8d8d8;
    padding-top: 25px;
    padding-bottom: 40px;
    .sub-title {
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: $secondary3;
      margin-top: 10px;
    }
  }
.affiliatedList {
  .title-main {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $secondary3;
  }
  .list {
    padding: 15px 0px 15px 0px;
    border-bottom: 1px solid #d8d8d8;
    .col {
        display: flex;
        align-items: center;
    }
    .list-item {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $titleBlack;
    }
  }
}
// ::-webkit-scrollbar {
//   width: 0px;
// }
.vertical {
  padding-left: 90px !important;
}
@media (max-width: 600px) {
  .affiliatedList {
    .list {
      padding-bottom: 0px !important;
    }
  }
}
</style>
